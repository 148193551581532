import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo2.png'


const _hoisted_1 = { class: "topBar" }

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopBar',
  setup(__props) {

const store = useStore();
const router = useRouter();

const handleLogout = () => {
    store.dispatch('auth/logout'); // 调用 Vuex 的登出 action
    router.push('/login'); // 登出后重定向到登录页面
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      class: "logo-img",
      crossorigin: "anonymous"
    }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "Bar" }, [
      _createElementVNode("div", { class: "topBarTitle" }, "金木妙影AI视频创作"),
      _createElementVNode("div", { class: "BarTitle" }, "垂类定制 / 一键生成 / 高效创作 / 版权无忧")
    ], -1)),
    _createElementVNode("button", {
      class: "logoutButton",
      onClick: handleLogout
    }, "登出")
  ]))
}
}

})