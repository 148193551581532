// store/modules/From3Dcartoon.ts
import { Module } from 'vuex';


export interface CartoonFormState {
    form: {
        cartoonTheme: string;
        storyCore: string;
        videoDuration: string;
        setting: string;
        characterSettings: string;
        reference: string;
    };
    errors: Record<keyof CartoonFormState['form'], boolean>;
    formLabels: Record<keyof CartoonFormState['form'], string>;
    requiredFields: Array<keyof CartoonFormState['form']>;  // 添加 requiredFields 数组
}

const cartoonForm: Module<CartoonFormState, any> = {
    namespaced: true,
    state: {
        form: {
            cartoonTheme: '',
            storyCore: '',
            videoDuration: '1分钟',
            setting: '',
            characterSettings: '',
            reference: '',
        },
        errors: {} as Record<keyof CartoonFormState['form'], boolean>, // 初始为空对象
        formLabels: {
            cartoonTheme: '动画主题',
            storyCore: '故事核心',
            videoDuration: '动画时长',
            setting: '故事背景',
            characterSettings: '角色设定',
            reference: '参考文案',
        },
        requiredFields: ['cartoonTheme', 'storyCore', 'videoDuration'],  // 添加 requiredFields 数组
    },
    mutations: {
        setField(state, { field, value }: { field: keyof CartoonFormState['form']; value: string }) {
            state.form[field] = value;
        },
        setError(state, { field, hasError }: { field: keyof CartoonFormState['form']; hasError: boolean }) {
            state.errors[field] = hasError;
        },
        setForm(state, formData: CartoonFormState['form']) {
            state.form = formData;
        },
        resetForm(state) {
            state.form = {} as CartoonFormState['form'];
            state.errors = {} as Record<keyof CartoonFormState['form'], boolean>;
        },
    },
    actions: {
        updateField({ commit }, payload: { field: keyof CartoonFormState['form']; value: string }) {
            commit('setField', payload);
        },
        validateForm({ state, commit }) {
            let isValid = true;
            const requiredFields: Array<keyof CartoonFormState['form']> = ['cartoonTheme', 'storyCore', 'videoDuration'];

            requiredFields.forEach((field) => {
                const hasError = !state.form[field];
                commit('setError', { field, hasError });
                if (hasError) {
                    console.log(`${field} 为空`);
                    isValid = false;
                }
            });

            console.log('验证结果:', isValid);
            return isValid;
        },
        async submitForm({ state, commit, dispatch }) {
            const isValid = await dispatch('validateForm');
            if (!isValid) {
                return { success: false, message: 'Validation failed' };
            }

            try {
                // 使用 dispatch 调用 plan 模块的 insertPage 
                dispatch('ViewPlan/insertPage', state.form, { root: true });  // 调用命名空间 
                return { success: true };
            } catch (error) {
                // 使用 instanceof 检查 error 是否为 Error 对象
                if (error instanceof Error) {
                    return { success: false, message: error.message };
                } else {
                    // 如果 error 不是 Error 对象，则返回一个通用的错误消息
                    return { success: false, message: 'An unknown error occurred' };
                }
            }
        },

    },
    getters: {
        formData: (state) => state.form,
        errors: (state) => state.errors,
        formLabels: (state) => state.formLabels, // 获取表单标签
        requiredFields: (state) => state.requiredFields,
    },
};

export default cartoonForm;
