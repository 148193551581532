import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "not-found" }


export default /*@__PURE__*/_defineComponent({
  __name: 'NotFoundView',
  setup(__props) {

// 你可以在这里添加一些逻辑或额外的功能

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "404 - 页面未找到", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, "抱歉，您访问的页面不存在。", -1)),
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("返回首页")
      ])),
      _: 1
    })
  ]))
}
}

})