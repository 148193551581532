import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "form-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "reference-upload" }
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 0,
  class: "required"
}
const _hoisted_7 = { class: "input-wrapper" }
const _hoisted_8 = ["id", "onUpdate:modelValue", "placeholder"]
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "reference-upload" }
const _hoisted_11 = { class: "gradient-mask" }

import { ref, reactive, watch, onMounted, computed, onBeforeUnmount, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useForm } from '@/composables/useForm'; // 引入通用表单处理逻辑
import SimpleBar from 'simplebar'; // 确保已安装 SimpleBar

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoForm',
  props: {
    selectedTitle: {}
  },
  emits: ['goBack'],
  setup(__props: any, { emit: __emit }) {



const store = useStore();

// 使用 useForm 钩子
const moduleName = store.getters['ViewPlan/getScriptType']; // 这里可以根据需要传递不同的模块名
const { form, errors, formLabels, isRequired, handleSubmit, handleFileUpload } = useForm(moduleName);

const iconPath = computed(() => store.getters['ViewPlan/getVideoIcon']);

// 获取当前路由信息
const route = useRoute();
const isPlanRoute = computed(() => route.path === '/plan');

// 返回上一页
const emit = __emit;
const goBack = () => emit('goBack');


// 获取占位符
const getPlaceholder = (key: string) => key === 'videoDuration' ? '单位默认为秒' : '';


// 手动触发表单提交
const submitForm = () => {
    handleSubmit();  // 调用 handleSubmit 直接处理提交逻辑
};

const smartFill = () => console.log('智能填写功能未开启，敬请期待');
const extractFromVideo = () => console.log('从视频中导出参考文案功能未开启，敬请期待');

const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'; // 先将高度设置为 auto，以重新计算
    const scrollHeight = textarea.scrollHeight; // 获取实际内容的高度

    if (scrollHeight <= 300) {
        // 如果内容高度小于等于 300px，则调整 textarea 高度
        textarea.style.height = `${scrollHeight}px`;
    } else {
        // 如果内容高度超过 300px，则设置高度为 400px，并允许滚动
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.maxHeight = '300px';
        textarea.style.overflow = 'auto';
    }
};

// 自动调整所有 textarea 高度
const adjustTextareaHeights = () => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => autoResize(textarea as HTMLTextAreaElement));
};



// 监控 v-model 数据变化，自动调整 textarea 高度
watch(form, adjustTextareaHeights, { deep: true });

const isAtBottom = ref(false);
const scrollContainer = ref<HTMLElement | null>(null);
const simpleBarInstance = ref<SimpleBar | null>(null);
const lastScrollTop = ref(0);
const scrollContainers = ref<HTMLElement[]>([]);  // 用来存储每个 scrollContainer 的引用
const simpleBarInstances = ref<SimpleBar[]>([]);

// 检查滚动位置
function checkScrollPosition() {
    let container = simpleBarInstance.value?.getScrollElement();
    if (container) {
        const scrollTop = container.scrollTop;
        const clientHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight;
        const tolerance = 15;

        if (scrollHeight <= clientHeight) {
            isAtBottom.value = true;
        } else if (scrollTop + clientHeight >= scrollHeight - tolerance && scrollTop !== lastScrollTop.value) {
            isAtBottom.value = true;
        } else {
            isAtBottom.value = false;
        }

        lastScrollTop.value = scrollTop;
    } else {
        isAtBottom.value = true;
    }
}

// 监听窗口调整大小
function handleResize() {
    checkScrollPosition();
}

onMounted(() => {
    if (scrollContainer.value) {
        simpleBarInstance.value = new SimpleBar(scrollContainer.value);
        const container = simpleBarInstance.value.getScrollElement();

        if (container) {
            checkScrollPosition(); // 初始化时检查滚动位置
            container.addEventListener('scroll', checkScrollPosition);
        } else {
            console.warn('Failed to get scroll element from SimpleBar instance');
        }

        // 添加窗口大小调整的监听
        window.addEventListener('resize', handleResize);
    };
    // 为每个容器初始化 SimpleBar 实例
    scrollContainers.value.forEach((container, index) => {
        const simpleBarInstance = new SimpleBar(container);
        simpleBarInstances.value.push(simpleBarInstance);
    });
});

onBeforeUnmount(() => {
    if (simpleBarInstance.value) {
        const container = simpleBarInstance.value.getScrollElement();
        if (container) {
            container.removeEventListener('scroll', checkScrollPosition);
        }

        // 清理 SimpleBar 实例
        simpleBarInstance.value.unMount();
        simpleBarInstance.value = null;
    }

    // 清理 SimpleBar 实例，避免内存泄漏
    simpleBarInstances.value.forEach((instance) => {
        instance.unMount();
    });

    // 移除resize监听
    window.removeEventListener('resize', handleResize);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "scrollContainer",
      ref: scrollContainer,
      class: "form-container",
      "data-simplebar": ""
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: iconPath.value,
          alt: "icon",
          class: "form-icon"
        }, null, 8, _hoisted_2),
        _createElementVNode("h2", null, _toDisplayString(_ctx.selectedTitle), 1),
        _createElementVNode("button", {
          class: "back-button",
          onClick: goBack
        }, "返回")
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_unref(handleSubmit) && _unref(handleSubmit)(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("label", null, "相关文档", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              type: "file",
              ref: "relatedDocInput",
              accept: ".doc,.docx",
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_unref(handleFileUpload)('doc'))),
              style: {"display":"none"}
            }, null, 544),
            _createElementVNode("button", {
              type: "button",
              for: "relatedDocInput",
              class: "upload-button",
              onClick: smartFill
            }, "上传文档智能填写")
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formLabels), (label, key) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "form-group",
            key: key
          }, [
            _createElementVNode("label", {
              for: String(key)
            }, [
              _createTextVNode(_toDisplayString(label) + " ", 1),
              (_unref(isRequired)(String(key)))
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "*"))
                : _createCommentVNode("", true)
            ], 8, _hoisted_5),
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("textarea", {
                id: String(key),
                "onUpdate:modelValue": ($event: any) => ((_unref(form)[key]) = $event),
                placeholder: getPlaceholder(String(key)),
                class: _normalizeClass({ 'input-error': _unref(errors)[key] })
              }, null, 10, _hoisted_8), [
                [_vModelText, _unref(form)[key]]
              ])
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_9, [
          _cache[4] || (_cache[4] = _createElementVNode("label", null, "视频文案", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("input", {
              type: "file",
              ref: "fileInput",
              accept: "video/*",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_unref(handleFileUpload)('video'))),
              style: {"display":"none"}
            }, null, 544),
            _createElementVNode("button", {
              type: "button",
              for: "fileInput",
              class: "upload-button",
              onClick: extractFromVideo
            }, "从视频中导出参考文案")
          ])
        ])
      ], 32)
    ], 512),
    _withDirectives(_createElementVNode("div", _hoisted_11, null, 512), [
      [_vShow, !isAtBottom.value]
    ]),
    (isPlanRoute.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "submit-button",
          onClick: submitForm
        }, "策划生成"))
      : _createCommentVNode("", true)
  ], 64))
}
}

})