<template>
    <div class="hotspot-main">
        <img src="@/assets/hotspot.png" class="index-img" crossorigin="anonymous">
    </div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">

.hotspot{
    margin: 0;
    padding: 0;
    text-align: center; /* 水平居中 */
}

.index-img{
    width: 75%;
    height: auto;
}

</style>