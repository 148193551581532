// store/modules/Cvideo.ts
import { queryVideoGeneration, invokeVideoGeneration, getAllVideoByUser } from '@/api/video'; // 引入接口方法

// Define the state structure for the video module
export interface State {
    videoList: Array<Video>; // 存储生成的视频列表
    currentVideoId: string; // 当前正要生成的视频的id
    currentPrompt: string; // 当前视频生成的提示词
    currentImage: string; // 当前用于生成视频的图片
    loading: boolean; // 是否正在加载中
    isGenerating: boolean; // 是否正在生成中`
}

// 视频对象接口
export interface Video {
    consumed_points: number; // 消耗的点数
    generation_status: string; // 视频生成状态 (e.g., "pending", "completed", "已完成")
    generation_time: string; // 视频生成时间 (ISO 格式或日期字符串)
    model: string; // 视频生成所使用的模型
    prompt: string; // 视频生成的提示词
    reference_image_url: string; // 引用的图片地址
    task_id: string; // 任务 ID
    user_id: number; // 用户 ID
    username: string; // 用户名
    video_id: number; // 视频唯一标识
    video_url: string; // 视频播放地址
}


// Initial state
const state: State = {
    videoList: [], // 初始视频列表为空
    currentVideoId: '1111222', // 初始化视频id为空
    currentPrompt: '', // 初始提示词为空
    currentImage: '', // 初始图片数组为空
    loading: false, // 默认不在加载状态
    isGenerating: false, // 默认不在生成状态
};

// Mutations
const mutations = {
    // 设置提示词
    setCurrentPrompt(state: State, currentPrompt: string) {
        state.currentPrompt = currentPrompt;
    },
    // 设置当前图片列表
    setCurrentImage(state: State, currentImage: string) {
        state.currentImage = currentImage;
    },
    // 更新视频列表
    setVodeoList(state: State, videos: Array<Video>) {
        state.videoList = videos;
    },
    // 添加一个视频到列表
    addVideo(state: State, video: Video) {
        state.videoList.unshift(video);
    },
    // 更新加载状态
    setLoading(state: State, isLoading: boolean) {
        state.loading = isLoading;
    },
    // 更新是否正在生成状态
    setIsGenerating(state: State, isGenerating: boolean) {
        state.isGenerating = isGenerating;
    },
    // 设置当前正在生成的视频id
    setCurrentVideoId(state: State, currentVideoId: string) {
        state.currentVideoId = currentVideoId;
    },
    // 更新视频列表
    setVideoList(state: State, videos: Array<Video>) {
        state.videoList = videos;
    },

};

// Actions
const actions = {


    async getAllVideoByUser({ commit, rootGetters, state }: any) {
        const userId = rootGetters['auth/userID'];

        // 检查 userId 是否存在
        if (!userId) {
            console.error('User ID is missing.');
            commit('setVideoList', []); // 设置空的视频列表
            return; // 直接结束函数
        }

        try {
            // 调用 API
            commit('setLoading', true); // 设置加载状态
            const response = await getAllVideoByUser(userId);
            console.log('获取用户的所有视频:', response);

            if (response?.success) {
                const videos = response.videos || [];
                console.log('Parsed videos:', videos);

                // 初始化视频列表
                commit('setVideoList', videos);

                // 从 videoList 提取所有 video_id
                const videoIdsToProcess = videos.map((video: any) => video.video_id);

                // 遍历 videoIdsToProcess 并处理每个视频
                for (const videoId of videoIdsToProcess) {
                    await processVideoById(videoId, { commit, state });
                }
            } else {
                console.error('Invalid response format or unsuccessful API response:', response);
                commit('setVideoList', []); // 设置空的视频列表
            }
        } catch (error: any) {
            console.error('Error fetching videos:', error.message || error);
            commit('setVideoList', []); // 设置空的视频列表
        } finally {
            commit('setLoading', false); // 取消加载状态
        }
    },

    // 生成视频
    async generateVideo(
        { commit, dispatch, rootGetters }: any,
        { videoPrompt, video_id, first_frame_image }: {
            videoPrompt: string;
            video_id: string;
            first_frame_image: string;
        }
    ) {

        const userId = rootGetters['auth/userID'];

        const username = rootGetters['auth/username'];

        commit('setIsGenerating', true)



        // // 每隔 5 秒打印一次状态
        // const intervalId = setInterval(() => {
        //     console.log(`Video ID: ${video_id} 在正在生成...`);
        // }, 5000); // 5000 毫秒 = 5秒

        // // 在 60 秒后停止打印
        // setTimeout(() => {
        //     clearInterval(intervalId); // 清除定时器，停止继续打印
        //     console.log(`视频生成状态检查结束。Video ID: ${video_id}`);
        //     commit('setIsGenerating', true)
        // }, 60000); // 60000 毫秒 = 60秒

        return new Promise<void>(async (resolve, reject) => {
            try {
                // 设置视频生成请求的数据
                const videoData = {
                    video_id: video_id,
                    prompt: videoPrompt,
                    model: 'video-01',
                    first_frame_image: first_frame_image,
                    user_id: userId,
                    user_name: username,
                };

                commit('addVideo', { ...videoData }); // 添加到视频列表    

                // 调用 API 生成视频，获取 taskId
                const response = await invokeVideoGeneration(videoData);
                const taskId = response.task_id;



                console.log(state.videoList);


                if (!taskId) throw new Error("未获取到任务ID");

                // 轮询任务状态，直到生成成功
                const checkStatus = async () => {
                    const result = await queryVideoGeneration(taskId, userId);
                    console.log(`视频生成任务状态: ${result.task_status}`);

                    if (result.task_status === 'Success') {
                        // 获取生成视频的 file_url
                        const fileUrl = result.file_url;
                        console.log(`视频生成成功，文件地址: ${fileUrl}`);

                        try {
                            await dispatch('getAllVideoByUser')
                        } catch (downloadError) {
                            console.error("下载视频文件失败:", downloadError);
                        }
                        // 生成完成后的逻辑
                        resolve(); // 轮询成功完成，通知外部
                    } else if (result.task_status === 'Processing') {
                        console.log(`视频正在生成..."`);
                        setTimeout(checkStatus, 3000); // 每隔3秒检查状态
                    } else {
                        console.error("视频生成失败或任务未完成:", result.task_status);
                    }
                };

                // 调用轮询检查
                checkStatus();
            } catch (error) {

            }

            // 最终设置 isGenerating 为 false，确保无论成功还是失败都被执行
            finally {
                commit('setIsGenerating', false)
            }
        });
    },
};


// 工具函数：下载视频并转换为 Blob
async function downloadAndConvertToBlob(video: any): Promise<any> {
    try {
        // 下载视频文件
        const res = await fetch(video.video_url);
        if (!res.ok) {
            throw new Error(`Failed to fetch video: ${video.video_url}`);
        }

        // 转换为 Blob
        const videoBlob = await res.blob();

        // 生成 Blob URL
        const videoBlobUrl = URL.createObjectURL(videoBlob);

        // 返回处理后的视频对象
        return {
            ...video,
            video_blob_url: videoBlobUrl, // 添加 Blob URL
        };
    } catch (error) {
        console.error(`Error downloading video ${video.video_id}:`, error);
        return video; // 返回原始数据，避免丢失
    }
}

async function processVideoById(videoId: string, { commit, state }: any): Promise<void> {
    try {
        // 获取对应的视频
        const video = state.videoList.find((v: any) => v.video_id === videoId);
        if (!video) {
            console.error(`Video with ID ${videoId} not found in videoList`);
            return;
        }

        // 下载并转换为 Blob
        const updatedVideo = await downloadAndConvertToBlob(video);

        // 更新视频列表
        const updatedList = [...state.videoList];
        const index = updatedList.findIndex((v: any) => v.video_id === videoId);

        if (index !== -1) {
            updatedList[index] = updatedVideo;
            commit('setVideoList', updatedList); // 更新状态
        }

        console.log(`视频 ${videoId} 下载并更新成功`);
    } catch (error) {
        console.error(`视频 ${videoId} 下载失败:`, error);
    }
}



// Getters
const getters = {
    // 获取当前视频列表
    videoList: (state: State) => state.videoList,
    // 获取当前提示词
    currentPrompt: (state: State) => state.currentPrompt,
    // 获取当前图片列表
    currentImage: (state: State) => state.currentImage,
    // 获取加载状态
    isLoading: (state: State) => state.loading,
    // 获取是否正在生成中
    isGenerating: (state: State) => state.isGenerating,
    // 获取当前正在生成的视频id
    currentVideoId: (state: State) => state.currentVideoId,
};

// Export the module
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
