import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  "data-simplebar": ""
}

import { onMounted } from 'vue';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
  const simpleBarElement = document.querySelector('[data-simplebar]');
  
  // 使用类型断言，将 simpleBarElement 断言为 HTMLElement
  if (simpleBarElement) {
    new SimpleBar(simpleBarElement as HTMLElement);
  }
});


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "footer-declaration" }, [
      _createElementVNode("span", null, "© 2024 本页面内容由AI技术生成，不涉及平台主观观念，内容仅作为信息参考，不构成任何法律建议，不对其性质、标准、准确性等基于任何形式的承诺或陈述。")
    ], -1))
  ]))
}
}

})