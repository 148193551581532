import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "top-bar" }
const _hoisted_3 = { class: "router-workbench" }

import TopBarComponent from '@/components/TopBar.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TopBarComponent)
    ]),
    _createElementVNode("nav", _hoisted_3, [
      _createVNode(_component_router_link, {
        to: "/hotspot",
        class: "link"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("AI热点")
        ])),
        _: 1
      }),
      _cache[5] || (_cache[5] = _createElementVNode("span", null, "/", -1)),
      _createVNode(_component_router_link, {
        to: "/plan",
        class: "link"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("创意")
        ])),
        _: 1
      }),
      _cache[6] || (_cache[6] = _createElementVNode("span", null, "/", -1)),
      _createVNode(_component_router_link, {
        to: "/storyboard",
        class: "link"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("分镜")
        ])),
        _: 1
      }),
      _cache[7] || (_cache[7] = _createElementVNode("span", null, "/", -1)),
      _createVNode(_component_router_link, {
        to: "/video-editing",
        class: "link"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("剪辑")
        ])),
        _: 1
      }),
      _cache[8] || (_cache[8] = _createElementVNode("span", null, "/", -1)),
      _createVNode(_component_router_link, {
        to: "/ai-material",
        class: "link"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("AI素材")
        ])),
        _: 1
      })
    ]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    })
  ]))
}
}

})