// /store/modules/auth.ts
import { Commit } from 'vuex';

interface State {
    token: string | null;
    username: string | null;
    userID: string | null; // 新增 userID
}

const state: State = {
    token: null,
    username: null,
    userID: null, // 新增 userID
};

const mutations = {
    setToken(state: State, token: string) {
        state.token = token;
    },
    setUsername(state: State, username: string) {
        state.username = username;
    },
    setUserID(state: State, userID: string) { // 新增 setUserID
        state.userID = userID;
    },
    clearToken(state: State) {
        state.token = null;
        state.username = null;
        state.userID = null; // 清空 userID
    }
};

const actions = {
    login({ commit }: { commit: Commit }, { token, username, userID }: { token: string, username: string, userID: string }) {
        commit('setToken', token);
        commit('setUsername', username);
        commit('setUserID', userID); // 在登录时设置 userID

        // 保存到本地存储
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
        localStorage.setItem('userID', userID);
    },
    logout({ commit }: { commit: Commit }) {
        commit('setToken', null);
        commit('setUsername', null);
        commit('setUserID', null);

        // 从本地存储中删除
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('userID');
    },
    initializeStore({ commit }: { commit: Commit }) {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const userID = localStorage.getItem('userID');
        if (token) {
            commit('setToken', token);
            commit('setUsername', username || '');
            commit('setUserID', userID || ''); // 在初始化时设置 userID
        }
    }
};

const getters = {
    token: (state: State) => state.token,
    username: (state: State) => state.username,
    userID: (state: State) => state.userID // 新增 userID getter
};

export default {
    namespaced: true, // 启用命名空间
    state,
    mutations,
    actions,
    getters,
};
