import instance from './index'; // 从 api/index.ts 引入封装的 axios 实例

// 获取会话的 API
export const getSession = (bot_id: string, userID: string) => {
    return instance.post('/api/getSession', { bot_id, userID }); // 提交 bot_id 和 userID 获取 sessionId
};

// 调用 chatWithBot 的 API
export const chatWithBot = (data: {
    user_token: string;
    conversation_id: string;
    bot_id: string;
    userInput: string;
    fileUrl?: string; // 文件 URL 可选
}) => {
    const formData = new FormData();
    formData.append("user_token", data.user_token);
    formData.append("conversation_id", data.conversation_id);
    formData.append("bot_id", data.bot_id);
    formData.append("userInput", data.userInput);

    // 如果有文件 URL，则添加到 FormData
    if (data.fileUrl) {
        formData.append("file_url", data.fileUrl);
    }

    // 使用封装的 axios 实例提交数据
    return instance.post('/chatWithBot', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'  // 指定 Content-Type 以便处理文件
        }
    });
};


// 生成分镜脚本
export const submitCopywriting = (copywritingData: any) => {
    return instance.post('/api/v2/storyboardGeneration', copywritingData) // 使用封装的 axios 实例提交表单数据
};


// 发生生成图片的请求
export const imgGeneration = (imageDescription: any) => {
    return instance.post('/api/v2/imgGeneration', imageDescription) // 使用封装的 axios 实例提交数据
};

// 调用后端 get_img 接口
export const getImg = async (params: { token: string; img_mask_id: string }) => {
    try {
        const response = await instance.get('/api/v2/get_img', {
            params, // 将 token 和 img_mask_id 作为查询参数传递
        });
        return response.data; // 返回响应数据
    } catch (error) {
        console.error('Error fetching image generation progress:', error);
        throw error; // 抛出错误以供调用者处理
    }
};

// 调用音频合成接口
export const narrationGeneration = (text: string, referenceId: string) => {
    return instance.get('/api/v2/soundSynthesis', {
        params: {
            text: text,
            reference_id: referenceId
        }
    });
};


// 调用后端的分镜保存接口
export const upsertStoryboard = (storyboardData: any) => {
    return instance.post('/api/v2/upsertStoryboard', storyboardData) // 使用封装的 axios 实例提交表单数据
};

// 调用后端的分镜获取接口
export const getStoryboard = (params: { user_id: string; project_id: string; storyboard_id: string }) => {
    return instance.get('/api/v2/getStoryboardByUserProjectAndStoryboardId', {
        params, //  将 user_id project_id 和 storyboard_id 作为查询参数传递
    });
};

// 调用后端的分镜删除接口
export const deleteStoryboard = (params: { user_id: string; project_id: string; storyboard_id: string }) => {
    return instance.post('/api/v2/deleteStoryboard', {
        params, // 将 user_id project_id 和 storyboard_id 作为查询参数传递
    });
};                                                                