import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import Vant from 'vant';
import 'vant/lib/index.css';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import VueLazyload from 'vue-lazyload';

const app = createApp(App);

// 调用 initializeStore 以从 localStorage 中加载 token 和其他信息
store.dispatch('auth/initializeStore');

// 配置 vue-lazyload
app.use(VueLazyload, {
  preLoad: 1.3,
  // error: 'https://i.imgur.com/zvNqJLA.png', // 加载失败的图片
  // loading: 'https://i.imgur.com/llF5iyg.gif', // 加载中的动画
  attempt: 1,
});

// 使用其他插件
app
  .use(store) // 使用 Vuex
  .use(router) // 使用 Vue Router
  .use(Vant) // 使用 Vant
  .mount('#app'); // 挂载应用
