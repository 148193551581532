import instance from './index'; // 引入封装的 axios 实例

// 异步函数：查询视频生成状态的 API
export const queryVideoGeneration = async (taskId: string, userId: string): Promise<any> => {
    try {
        const response = await instance.get('/api/v2/queryVideoGeneration', {
            params: { task_id: taskId, user_id: userId }
        });
        return response.data;
    } catch (error) {
        console.error("Error querying video generation status:", error);
        throw error; // 抛出错误，以便在调用时处理
    }
};

// 异步函数：触发视频生成的 API
export const invokeVideoGeneration = async (videoData: {
    video_id: string;
    prompt: string;
    model: string;
    first_frame_image: string;
    user_id: string;
    user_name: string
}): Promise<any> => {
    try {
        const response = await instance.post('/api/v2/invokeVideoGeneration', videoData);
        return response.data;
    } catch (error) {
        console.error("Error invoking video generation:", error);
        throw error; // 抛出错误，以便在调用时处理
    }
};
