<template>
    <div class="separate"></div>
    <div class="voiceover-page">
        <div class="container">
            <!-- 文本输入区域 -->
            <div class="text-area">
                <h2>配音文案</h2>
                <textarea v-model="scriptText" :maxlength="maxLength" placeholder="请输入配音文案..."></textarea>
                <p>{{ scriptText.length }}/{{ maxLength }}</p>
            </div>

            <!-- 音色选择 -->
            <div class="character-selection">
                <h2>选择音色</h2>
                <div class="voice-list">
                    <div v-for="voice in soundProfiles" :key="voice.id" class="voice-item"
                        :class="{ selected: selectedVoice === voice.id }" @click="selectVoice(voice.id)">
                        <img :src="voice.icon" alt="Voice Avatar" crossorigin="anonymous" />
                        <div class="voice-info">
                            <p>{{ voice.sound_name }}</p>
                            <p class="voice-description">{{ voice.description }}</p>
                        </div>

                        <i @click.stop="playVoiceSample(voice)" class="btn btn-outline-secondary"
                            :class="playingAudioId === voice.id ? 'bi-pause-fill' : 'bi-play-fill'"></i>
                    </div>
                </div>
            </div>

            <!-- 生成的配音区域 -->
            <div class="voiceover-output">
                <h2>配音成品</h2>
                <div v-for="voiceover in generatedVoiceovers" :key="voiceover.id" class="voiceover-container">
                    <div class="voiceover-item">
                        <img :src="voiceover.icon" alt="配音图片" class="voiceover-image" crossorigin="anonymous" />
                        <div class="voiceover-details">
                            <p>{{voiceover.sound_name + '-' + voiceover.formatted_created_at}}</p>
                            <p class="voiceover-script">{{ voiceover.script_text }}</p>
                        </div>
                    </div>
                    <audio :src="voiceover.audio_url" controls crossorigin="anonymous" ></audio> <!-- 将 audio 放在 voiceover-item 之外 -->
                </div>
                <button @click="generateVoiceover">生成</button>
            </div>
        </div>
    </div>
</template>
 
<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex';


// 获取 Vuex store
const store = useStore()

// 文案内容
// 使用 computed 双向绑定 Vuex 中的 scriptText
const scriptText = computed({
    // 通过 get 获取 Vuex 中的 scriptText
    get: () => store.getters['Cvoiceover/getScriptText'],

    // 通过 set 更新 Vuex 中的 scriptText
    set: (newText) => {
        store.commit('Cvoiceover/setScriptText', newText) // 提交 mutation 更新 scriptText
    }
})

const maxLength = computed(() => store.getters['Cvoiceover/getMaxLength'])

// 从 Vuex 中获取音色数据和选中的音色
const soundProfiles = computed(() => store.getters['Cvoiceover/getSoundProfiles'])
const selectedVoice = computed(() => store.getters['Cvoiceover/getSelectedVoice'])

// 获取当前用户ID
const userId = computed(() => store.getters['auth/userID']);
console.log('userId ==== ' + userId.value);


// 播放状态
const playingAudioId = ref<number | null>(null) // 当前播放的音频 ID
let audio: HTMLAudioElement | null = null

// 获取当前选中音色的配音记录
const generatedVoiceovers = computed(() => {
    // 从 Vuex 获取当前选中的 soundId
    const soundId = store.getters['Cvoiceover/getConvertedSoundId']
    console.log('当前 soundId:', soundId);

    // 如果 soundId 为空，使用用户的配音记录（getUserVoiceoverRecords）
    if (!soundId) {
        return store.getters['Cvoiceover/getUserVoiceoverRecords'](userId.value) || []
    }

    // 否则使用选中的 soundId 配音记录
    return store.getters['Cvoiceover/getVoiceoverRecords'](soundId) || []
})


// 监听 selectedVoice 和 userId 的变化
watch([selectedVoice, userId], async ([newSelectedVoice, newUserId]) => {
    if (newSelectedVoice && newUserId) {
        console.log(`Selected voice changed to ${newSelectedVoice}, checking if voiceover data is needed.`);
        await store.dispatch('Cvoiceover/fetchVoiceoverIfNeeded', { userId: newUserId, soundId: newSelectedVoice });
        // 打印当前的 voiceoverRecords
        console.log('Current voiceoverRecords:', store.state.Cvoiceover.voiceoverRecords);
    }
}, { immediate: true });  // 确保初始化时触发一次

const selectVoice = (id: number) => {
    // 如果点击的音色 ID 与当前选中的音色 ID 相同，则取消选择
    if (store.getters['Cvoiceover/getSelectedVoice'] === id) {
        store.commit('Cvoiceover/setSelectedVoice', null) // 取消选择，将 selectedVoice 设置为空
    } else {
        // 更新选中的音色 ID
        store.commit('Cvoiceover/setSelectedVoice', id)
    }
}


// 缓存音频对象
const cachedAudios = ref<{ [key: number]: HTMLAudioElement }>({})

// 播放示例配音
const playVoiceSample = (voice: any) => {
    selectVoice(voice.id)

    // 如果当前点击的音频是正在播放的音频
    if (playingAudioId.value === voice.id && audio) {
        audio.pause()
        playingAudioId.value = null
    } else {
        // 停止当前播放的音频
        if (audio) audio.pause()

        // 检查音频 URL 是否有效
        const sampleUrl = voice.voice_sample
        if (sampleUrl) {
            // 如果该音频已经缓存，直接使用缓存的音频对象
            if (cachedAudios.value[voice.id]) {
                audio = cachedAudios.value[voice.id]
            } else {
                // 否则创建一个新的 Audio 对象，并缓存它
                audio = new Audio(sampleUrl)
                audio.crossOrigin = 'anonymous'  // 设置跨域属性
                audio.preload = 'auto' // 开始预加载音频
                cachedAudios.value[voice.id] = audio // 缓存音频对象
            }

            // 播放音频
            audio.play().then(() => {
                playingAudioId.value = voice.id
            }).catch((error) => {
                console.error('音频播放失败', error)
            })

            // 播放结束时重置状态
            audio.onended = () => {
                playingAudioId.value = null
            }
        } else {
            console.error('音频 URL 无效')
        }
    }
}

// 生成配音方法
const generateVoiceover = async () => {
    if (!scriptText.value.trim()) {
        alert("请填写配音文案")
        return
    }
    if (!selectedVoice.value) {
        alert("请选择一个音色")
        return
    }
    try {

        // 调用 Vuex 的 createVoiceover action
        await store.dispatch('Cvoiceover/createVoiceover')
        // alert("配音生成成功")
    } catch (error) {
        console.error("生成配音出错:", error)
        alert("生成配音时出错，请重试")
    }
}

// 加载音色数据
onMounted(() => {
    store.dispatch('Cvoiceover/fetchSoundProfiles') // 调用 Vuex action 获取音色数据
    store.dispatch('Cvoiceover/fetchAllVoiceoverByUser', { userId: userId.value });
})


</script>


<style scoped lang="scss">
.separate {
    height: 10px;
}

.voiceover-page {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    width: 90%;
    margin: auto; // 水平居中

    .container {
        display: flex;
        gap: 10px;
    }

    .text-area,
    .character-selection,
    .voiceover-output {
        background-color: white;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 300px;
        overflow-y: auto;
        max-height: 380px;

        h2 {
            margin-top: 0;
            font-size: 1.2em;
            margin-bottom: 10px;
        }
    }

    .text-area {
        width: 400px;

        textarea {
            width: 95%;
            height: 300px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            resize: none;
        }

        p {
            text-align: right;
            margin: 0;
            color: #888;
        }
    }

    .character-selection {
        width: 450px;

        .voice-list {
            margin: auto; // 水平居中
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }

        .voice-item {
            display: flex;
            gap: 10px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            cursor: pointer;
            width: 180px;

            &.selected {
                background-color: #e0f7fa;
                border-color: #00acc1;
            }

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .voice-info {
                display: flex;
                flex-direction: column; // 垂直排列名称和简介
                align-items: flex-start; // 左对齐所有子元素

                p {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            .voice-description {
                font-size: 0.9em;
                color: #666;
                margin: 0 !important;
                text-align: left;
            }

            i {
                background: none;
                border: none;
                cursor: pointer;
                font-size: 1.3em;
                /* 增大图标的大小，可以根据需要调整 */
                width: 1.3em;
                /* 设置固定的宽度 */
                height: 1.3em;
                /* 设置固定的高度，和宽度一致，确保为圆形 */
                margin-left: auto;
                margin-right: 0%;
                padding: 0;
                /* 取消内边距，确保图标不会被压缩 */
                border-radius: 50%;
                /* 圆角设置为圆形 */
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color 0.3s, transform 0.3s;
                /* 添加交互动画 */
                margin-top: auto;
                /* 上下居中 */
                margin-bottom: auto;
                /* 上下居中 */
            }

            i:hover {
                background-color: rgba(0, 0, 0, 0.1);
                /* 添加 hover 背景色 */
                transform: scale(1.1);
                /* 放大效果 */
            }

            i:active {
                transform: scale(0.9);
                /* 点击时缩小效果 */
            }
        }
    }

    .voiceover-output {
        position: relative;
        padding: 0;
        padding-top: 10px;
        /* 确保父容器是相对定位 */
        max-height: 400px;
        min-width: 340px;
        width: auto;
        .voiceover-container {
            display: flex;
            flex-direction: column;
            gap: 0px;
            margin-bottom: 15px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding-bottom: 0px;
            overflow-y: hidden;
            margin-top: 10px;
            margin-left: 15px;
            margin-right: 15px;
            .voiceover-item {
                display: flex;
                gap: 10px;
                align-items: center;

                .voiceover-image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-left: 5px;
                }

                .voiceover-details {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    align-items: flex-start;
                    font-size: 14px;

                    p {
                        text-align: left;
                        width: 100%;
                        font-size: 1em;
                        margin: 0;
                    }

                    .voiceover-script {
                        font-size: 0.9em;
                        color: #666;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 220px;
                        margin-top: 5px;
                        text-align: left;
                    }
                }
            }

            audio {
                width: 100%;
                margin-top: 5px;
            }
        }

        button {
            width: calc(100% - 20px);
            padding: 10px;
            background-color: #2196f3;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 1em;
            position: sticky;
            /* 使用 sticky 定位 */
            bottom: 0;
            /* 固定在底部 */
            left: 10px;
            z-index: 1;
            /* 确保按钮显示在其他内容之上 */
        }

        button:hover {
            background-color: #3b88db;
        }
    }



}
</style>