<template>
  <div class="copywriting-setting">
    <!-- 顶部导航栏 -->
    <nav class="top-navbar">
      <span class="nav-link" :class="{ active: activeView === 'video settings' }"
        @click="activeView = 'video settings'">
        视频设置
      </span>
      <span class="nav-link" :class="{ active: activeView === 'advanced setting' }"
        @click="activeView = 'advanced setting'">
        高级设置
      </span>
      <span class="nav-link" :class="{ active: activeView === 'more' }" @click="activeView = 'more'">
        更多
      </span>
    </nav>

    <!-- 内容区域 -->
    <div class="content-area">
      <div v-if="activeView === 'video settings'">
        <!-- 视频设置内容 -->
        <div class="video-options">
          <!-- 视频比例 -->
          <div class="radio-group">
            <label class="group-label">视频比例：</label>
            <div class="radio-options">
              <label><input type="radio" value="16:9" v-model="aspectRatio" /> 16:9</label>
              <label><input type="radio" value="9:16" v-model="aspectRatio" /> 9:16</label>
              <label><input type="radio" value="4:3" v-model="aspectRatio" /> 4:3</label>
              <label><input type="radio" value="3:4" v-model="aspectRatio" /> 3:4</label>
              <label><input type="radio" value="1:1" v-model="aspectRatio" /> 1:1</label>
            </div>
          </div>

          <!-- 素材类型 -->
          <div class="radio-group">
            <label class="group-label">素材类型：</label>
            <div class="radio-options">
              <label><input type="radio" value="在线素材" v-model="materialType" /> 在线素材</label>
              <label><input type="radio" value="AI生成素材" v-model="materialType" /> AI生成素材</label>
            </div>
          </div>

          <!-- 旁白音调 -->
          <div class="radio-group">
            <label class="group-label">旁白音调：</label>
            <div class="radio-options">
              <label><input type="radio" value="男-宣传片" v-model="voiceTone" /> 男-宣传片</label>
              <label><input type="radio" value="女-温柔知性" v-model="voiceTone" /> 女-温柔知性</label>
              <label><input type="radio" value="男-叙事" v-model="voiceTone" /> 男-叙事</label>
              <label><input type="radio" value="男-新闻广播" v-model="voiceTone" /> 男-新闻广播</label>
              <label><input type="radio" value="男-科技" v-model="voiceTone" /> 男-科技</label>
              <label><input type="radio" value="男-讲故事2" v-model="voiceTone" /> 男-讲故事2</label>
              <label><input type="radio" value="男-讲故事" v-model="voiceTone" /> 男-讲故事</label>
              <label><input type="radio" value="男-房地产" v-model="voiceTone" /> 男-房地产</label>
              <label><input type="radio" value="男-MG动画" v-model="voiceTone" /> 男-MG动画</label>
              <label><input type="radio" value="女-讲故事" v-model="voiceTone" /> 女-讲故事</label>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeView === 'advanced setting'">
        <p>这是是高级设置的内容</p>
      </div>

      <div v-if="activeView === 'more'">
        <p>这里是更多内容。</p>
      </div>

      <div class="send-button">
        <button class="jumpButton" @click="generateStoryboard">分镜生成</button>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; // 引入 Vue Router

const router = useRouter(); // 获取 router 实例
const store = useStore();

// 使用 Vuex 的 getters 获取状态，并通过 set 方法更新 Vuex 中的状态
const activeView = computed({
  get: () => store.getters['VideoSettings/getActiveView'],
  set: (newView) => store.commit('VideoSettings/setActiveView', newView),
});

const aspectRatio = computed({
  get: () => store.getters['VideoSettings/getAspectRatio'],
  set: (newRatio) => store.commit('VideoSettings/setAspectRatio', newRatio),
});

const materialType = computed({
  get: () => store.getters['VideoSettings/getMaterialType'],
  set: (newType) => store.commit('VideoSettings/setMaterialType', newType),
});

const voiceTone = computed({
  get: () => store.getters['VideoSettings/getVoiceTone'],
  set: (newTone) => store.commit('VideoSettings/setVoiceTone', newTone),
});


// 生成脚本
const generateStoryboard = async () => {
  router.push({ name: 'storyboard' }); // 跳转到 storyboard 页面
  // 调用 submitCopywriting 方法
  await store.dispatch('VideoSettings/submitCopywriting');
};


</script>


<style lang="scss" scoped>
.copywriting-setting {
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  // background-color: #1F2229;
  background-color: #2b2e3d;
  max-height: 600px;
  overflow-y: auto;
  /* 超出高度时显示垂直滚动条 */
}

.top-navbar {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.nav-link {
  color: #b9e4dc;
  margin: 0 15px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 16px;
}

.nav-link:hover {
  color: #0CC5B3;

}

.nav-link.active {
  color: #0CC5B3;
  /* 选中时的颜色 */

}

.content-area {
  position: relative;
  /* 使 form 容器成为定位上下文 */
  padding-bottom: 40px;
  /* 留出足够空间给 submit-button，防止遮挡 */
  flex: 1;
  margin: 0;
  grid-template-columns: 1fr;
  justify-items: center;
  border-radius: 10px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 490px;
}

.content-area p {
  color: white;
  font-size: 15px;
  padding: 20px;
  margin: 10px;
}

.video-options {
  margin: 10px 30px;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.group-label {
  font-size: 14px;
  font-weight: bold;
}

.radio-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  /* 允许选项换行 */
  align-items: center;
  font-size: 15px;
}


/* 调整 radio 大小 */
.radio-options input[type="radio"] {
  width: 15px;
  /* 设置宽度 */
  height: 15px;
  /* 设置高度 */
  transform: scale(0.8);
  /* 缩小比例，可根据需要调整 */
  margin-right: 5px;
  /* 调整与文字之间的间距 */
  font-size: 15px;
}

.send-button {
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
}

.send-button button {
  width: 100%;
  background-color: #1a73e8;
  color: white;
  font-size: 15px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  position: absolute;
  /* 使用 sticky */
  bottom: 0;
  /* 定位到底部 */
  right: 0px;
  /* 定位到右侧 */
  z-index: 10;
  /* 确保按钮位于其他内容之上 */

}

.send-button button:hover {
  background-color: #135ba1;
}
</style>