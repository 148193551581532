// store/modules/FromBroadcasting.ts
import { Module } from 'vuex';

export interface BroFormState {
    form: {
        agriculturalProductName: string;
        originPlace: string;
        videoDuration: string;
        sellingPoint: string;
        storySpirit: string;
        mainCharacterImage: string;

        detailedIntroduction: string;
        reference: string;
    };
    errors: Record<keyof BroFormState['form'], boolean>;
    formLabels: Record<keyof BroFormState['form'], string>;
    requiredFields: Array<keyof BroFormState['form']>;  // 添加 requiredFields 数组
}

const broForm: Module<BroFormState, any> = {
    namespaced: true,
    state: {
        form: {
            agriculturalProductName: '',
            originPlace: '',
            videoDuration: '1分钟',
            sellingPoint: '',
            storySpirit: '',
            mainCharacterImage: '',

            detailedIntroduction: '',
            reference: '',
        },
        errors: {} as Record<keyof BroFormState['form'], boolean>, // 初始为空对象
        formLabels: {
            agriculturalProductName: '农产品名称',
            originPlace: '产地',
            videoDuration: '视频时长',
            sellingPoint: '主打卖点',
            storySpirit: '故事精神',
            mainCharacterImage: '主角形象',
            detailedIntroduction: '详细介绍',
            reference: '参考文案',
        },
        requiredFields: ['agriculturalProductName', 'originPlace', 'videoDuration','sellingPoint'],  // 添加 requiredFields 数组

    },
    mutations: {
        setField(state, { field, value }: { field: keyof BroFormState['form']; value: string }) {
            state.form[field] = value;
        },
        setError(state, { field, hasError }: { field: keyof BroFormState['form']; hasError: boolean }) {
            state.errors[field] = hasError;
        },
        setForm(state, formData: BroFormState['form']) {
            state.form = formData;
        },
        resetForm(state) {
            state.form = {} as BroFormState['form'];
            state.errors = {} as Record<keyof BroFormState['form'], boolean>;
        },
    },
    actions: {
        updateField({ commit }, payload: { field: keyof BroFormState['form']; value: string }) {
            commit('setField', payload);
        },
        validateForm({ state, commit }) {
            let isValid = true;
            const requiredFields: Array<keyof BroFormState['form']> = ['agriculturalProductName', 'originPlace', 'videoDuration', 'sellingPoint'];

            requiredFields.forEach((field) => {
                const hasError = !state.form[field];
                commit('setError', { field, hasError });
                if (hasError) {
                    console.log(`${field} 为空`);
                    isValid = false;
                }
            });

            console.log('验证结果:', isValid);
            return isValid;
        },
        async submitForm({ state, commit, dispatch }) {
            const isValid = await dispatch('validateForm');
            if (!isValid) {
                return { success: false, message: 'Validation failed' };
            }

            try {
                // 使用 dispatch 调用 plan 模块的 insertPage 
                dispatch('ViewPlan/insertPage', state.form, { root: true });  // 调用命名空间 
                return { success: true };
            } catch (error) {
                // 使用 instanceof 检查 error 是否为 Error 对象
                if (error instanceof Error) {
                    return { success: false, message: error.message };
                } else {
                    // 如果 error 不是 Error 对象，则返回一个通用的错误消息
                    return { success: false, message: 'An unknown error occurred' };
                }
            }
        },

    },
    getters: {
        formData: (state) => state.form,
        errors: (state) => state.errors,
        formLabels: (state) => state.formLabels, // 获取表单标签
        requiredFields: (state) => state.requiredFields,
    },
};

export default broForm;
