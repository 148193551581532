// store/modules/advertising.ts
import { Module } from 'vuex';

export interface TrailerFormState {
    form: {
        companyName: string;
        companyTitle: string;
        videoDuration: string;
        companyIntro: string,
        productIntro: string;
        useCases: string;
        serviceIntro: string;
        advantages: string;
        teamIntro: string;
        contact: string;
        reference: string;
    };
    errors: Record<keyof TrailerFormState['form'], boolean>;
    formLabels: Record<keyof TrailerFormState['form'], string>;
    requiredFields: Array<keyof TrailerFormState['form']>;  // 添加 requiredFields 数组
}

const trailerForm: Module<TrailerFormState, any> = {
    namespaced: true,
    state: {
        form: {
            companyName: '',
            companyTitle: '',
            videoDuration: '',
            companyIntro: '',
            productIntro: '',
            useCases: '',
            serviceIntro: '',
            advantages: '',
            teamIntro: '',
            contact: '',
            reference: '',
        },
        errors: {} as Record<keyof TrailerFormState['form'], boolean>, // 初始为空对象
        formLabels: {
            companyName: '企业名称',
            companyTitle: '企业简称',
            videoDuration: '视频时长',
            companyIntro: '企业简介',
            productIntro: '产品介绍',
            useCases: '使用场景',
            serviceIntro: '服务介绍',
            advantages: '企业优势',
            teamIntro: '团队介绍',
            contact: '联系方式',
            reference: '参考文献',
        },
        requiredFields: ['companyName', 'companyTitle', 'videoDuration'],  // 添加 requiredFields 数组
    },
    mutations: {
        setField(state, { field, value }: { field: keyof TrailerFormState['form']; value: string }) {
            state.form[field] = value;
        },
        setError(state, { field, hasError }: { field: keyof TrailerFormState['form']; hasError: boolean }) {
            state.errors[field] = hasError;
        },
        setForm(state, formData: TrailerFormState['form']) {
            state.form = formData;
        },
        resetForm(state) {
            state.form = {} as TrailerFormState['form'];
            state.errors = {} as Record<keyof TrailerFormState['form'], boolean>;
        },
    },
    actions: {
        updateField({ commit }, payload: { field: keyof TrailerFormState['form']; value: string }) {
            commit('setField', payload);
        },
        validateForm({ commit, state }) {
            const requiredFields: Array<keyof TrailerFormState['form']> = ['companyName', 'companyTitle', 'videoDuration'];
            let isValid = true;

            requiredFields.forEach((field) => {
                const hasError = !state.form[field];
                commit('setError', { field, hasError });
                if (hasError) isValid = false;
            });

            if (isNaN(Number(state.form.videoDuration)) || Number(state.form.videoDuration) <= 0) {
                commit('setError', { field: 'videoDuration', hasError: true });
                isValid = false;
            }

            return isValid;
        },
    },
    getters: {
        formData: (state) => state.form,
        errors: (state) => state.errors,
        formLabels: (state) => state.formLabels, // 获取表单标签
        requiredFields: (state) => state.requiredFields,
    },
};

export default trailerForm;
