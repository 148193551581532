import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img1.png'


const _hoisted_1 = { class: "hotspot-main" }


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsCharacter',
  props: {
    character: {
        type: Object,
        required: true,
    },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: _imports_0,
      class: "index-img",
      crossorigin: "anonymous"
    }, null, -1)
  ])))
}
}

})