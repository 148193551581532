<template>


    <div class="image">

        <h1> This is image page </h1>


    </div>


    
</template>

<script lang="ts" setup>



</script>

<style scoped lang="scss">

h1{
    color: white;
}

</style>