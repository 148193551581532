// store/modules/material.ts
import { Module } from 'vuex';

// Define the state structure for the material module
export interface State {
    selectedOption: string;
}

// Initial state
const state: State = {
    selectedOption: ' ', // Default option
};

// Mutations
const mutations = {
    setSelectedOption(state: State, option: string) {
        state.selectedOption = option;
    },
};

// Actions
const actions = {
    updateSelectedOption({ commit }: { commit: any; }, option: string) {
        commit('setSelectedOption', option);
        console.log(state.selectedOption)
    },
};

// Getters
const getters = {
    getSelectedOption(state: State): string {
        return state.selectedOption;
    },
};

// Export the module
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
