// store/index.ts
import { createStore } from 'vuex';
import ViewPlan from './modules/ViewPlan';
import advForm from './modules/FormAdvertising';
import trailerForm from './modules/FormTrailer';
import broForm from './modules/FormBroadcasting';
import cartoonForm from './modules/Form3DCartoon';
import VideoSettings from './modules/VideoSettings';
import ViewStoryboard from './modules/ViewStoryboard';
import ViewEdit from './modules/ViewEdit';
import ViewMaterial from './modules/ViewMaterial';
import Cvoiceover from './modules/Cvoiceover';
import Cvideo from './modules/Cvideo';
import auth from './modules/auth';

export default createStore({
  state: {

  },


  mutations: {

  },


  getters: {

  },



  modules: {
    ViewPlan,
    advertising: advForm,
    trailer: trailerForm,
    broadcasting: broForm,
    cartoon:cartoonForm,
    VideoSettings,
    ViewStoryboard,
    ViewEdit,
    ViewMaterial,
    auth,
    Cvoiceover, 
    Cvideo,
  }
});
