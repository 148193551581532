<template>
    <div class="video-type">
        <!-- 遍历过滤后的卡片，根据父组件传入的 `type` 显示相应的卡片 -->
        <VideoCardComponents v-for="card in filteredCards" :key="card.id" :icon="card.icon" :title="card.title"
            @click="showCorporateView(card)" />
    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';
import VideoCardComponents from '@/components/VideoCard.vue';

// 接收父组件传入的单一 `type`
const props = defineProps({
    type: {
        type: String,
        required: true,
    },
});

// 状态管理
const store = useStore();

// 定义不同类型的视频卡片数据集合
const ad_cards = [
    {
        id: '100001',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/产品广告.jpg'),
        title: '产品广告',
    },
    {
        id: '100002',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/餐饮食品2.jpg'),
        title: '餐饮食品',
    },
    {
        id: '100003',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/服装时尚.jpg'),
        title: '服装时尚',
    },
    {
        id: '100004',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/公益广告.jpg'),
        title: '公益广告',
    },
    {
        id: '100005',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/家具家电.jpg'),
        title: '家具家电',
    },
    {
        id: '100006',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/美妆护肤.jpg'),
        title: '美妆护肤',
    },
    {
        id: '100007',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/汽车.jpg'),
        title: '汽车',
    },
    {
        id: '100008',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/手机数码.jpg'),
        title: '手机数码',
    },
    {
        id: '100008',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/体育运动.jpg'),
        title: '体育运动',
    },
    {
        id: '100009',
        type: 'advertising',
        icon: require('@/assets/classify_pic/adPic/医疗保健.jpg'),
        title: '医疗保健',
    },
];

const trailer_cards = [
    {
        id: '200001',
        type: 'trailer',
        icon: require('@/assets/classify_pic/trailer/城市形象.jpg'),
        title: '城市形象',
    },
    {
        id: '200002',
        type: 'trailer',
        icon: require('@/assets/classify_pic/trailer/非遗文化.jpg'),
        title: '非遗文化',
    },
    {
        id: '200003',
        type: 'trailer',
        icon: require('@/assets/classify_pic/trailer/工业制造.jpg'),
        title: '工业制造',
    },
    {
        id: '200004',
        type: 'trailer',
        icon: require('@/assets/classify_pic/trailer/科技生物.jpg'),
        title: '科技生物',
    },
    {
        id: '200005',
        type: 'trailer',
        icon: require('@/assets/classify_pic/trailer/企业形象.jpg'),
        title: '企业形象',
    },
    {
        id: '200006',
        type: 'trailer',
        icon: require('@/assets/classify_pic/trailer/乡村振兴.jpg'),
        title: '乡村振兴',
    },
    {
        id: '200007',
        type: 'trailer',
        icon: require('@/assets/classify_pic/trailer/学校宣传.jpg'),
        title: '学校宣传',
    }
];

const cartoon_cards = [
    {
        id: '300001',
        type: 'cartoon',
        icon: require('@/assets/classify_pic/cartoon/MG动画.jpg'),
        title: 'MG动画',
    },
    {
        id: '300002',
        type: 'cartoon',
        icon: require('@/assets/classify_pic/cartoon/迪士尼皮克斯.jpg'),
        title: '迪士尼皮克斯',
    },
    {
        id: '300003',
        type: 'cartoon',
        icon: require('@/assets/classify_pic/cartoon/工业演示.jpg'),
        title: '工业演示',
    },
    {
        id: '300004',
        type: 'cartoon',
        icon: require('@/assets/classify_pic/cartoon/三维动画.jpg'),
        title: '三维动画',
    },
    {
        id: '300005',
        type: 'cartoon',
        icon: require('@/assets/classify_pic/cartoon/治愈动画.jpg'),
        title: '治愈动画',
    },
];

const short_story_cards = [
    {
        id: '400001',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortPlay/爆笑喜剧.jpg'),
        title: '爆笑喜剧',
    },
    {
        id: '400002',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortPlay/都市爱情.jpg'),
        title: '都市爱情',
    },
    {
        id: '400003',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortPlay/功夫动作.jpg'),
        title: '功夫动作',
    },
    {
        id: '400004',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortPlay/古装短剧.jpg'),
        title: '古装短剧',
    },
    {
        id: '400005',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortPlay/惊悚.jpg'),
        title: '惊悚',
    },
    {
        id: '400006',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortPlay/科幻.jpg'),
        title: '科幻',
    },
    {
        id: '400007',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortPlay/奇幻冒险.jpg'),
        title: '奇幻冒险',
    },
];

const short_video_cards = [
    {
        id: '500001',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/口播种草-农产品励志故事.jpg'),
        title: '口播种草-励志故事',
    },
    {
        id: '500002',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/服装时尚.jpg'),
        title: '服装时尚',
    },
    {
        id: '500003',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/家具家电.jpg'),
        title: '家具家电',
    },
    {
        id: '500004',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/美妆护肤.jpg'),
        title: '美妆护肤',
    },
    {
        id: '500005',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/视频饮料.jpg'),
        title: '视频饮料',
    },
    {
        id: '500006',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/体育运动.jpg'),
        title: '运动健康',
    },
    {
        id: '500007',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/中医养生.jpg'),
        title: '中医养生',
    },
    {
        id: '500008',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/shortVideo/口播种草.jpg'),
        title: '口播种草',
    }
];

const ip_creation_cards = [
    {
        id: '600001',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/ipCreation/电影解说.jpg'),
        title: '电影解说',
    },
    {
        id: '600002',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/ipCreation/萌宠可爱.jpg'),
        title: '萌宠可爱',
    },
    {
        id: '600003',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/ipCreation/情感鸡汤.jpg'),
        title: '情感鸡汤',
    },
    {
        id: '600004',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/ipCreation/生活科普.jpg'),
        title: '生活科普',
    },
    {
        id: '600005',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/ipCreation/颜值舞蹈.jpg'),
        title: '颜值舞蹈',
    },
    {
        id: '600006',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/ipCreation/知识分享.jpg'),
        title: '知识分享',
    },
    {
        id: '600007',
        type: 'broadcasting',
        icon: require('@/assets/classify_pic/ipCreation/历史解说.jpg'),
        title: '历史解说',
    },
];

// 根据父组件传入的 `type` 选择相应的卡片集合
const filteredCards = computed(() => {
    switch (props.type) {
        case 'ad':
            return ad_cards;
        case 'trailer':
            return trailer_cards;
        case 'cartoon':
            return cartoon_cards;
        case 'short_story':
            return short_story_cards;
        case 'short_video':
            return short_video_cards;
        case 'ip_creation':
            return ip_creation_cards;
        default:
            return [];
    }
});

// 显示 CorporateView 的函数
const showCorporateView = (card: typeof ad_cards[number]) => {
    store.commit('ViewPlan/setSelectedTitle', card.title);
    store.commit('ViewPlan/setCurrentScriptType', card.type);
    store.commit('ViewPlan/setVideoData', card);
    store.commit('ViewPlan/setIsCorporateViewVisible', true);
    store.dispatch('ViewPlan/updateScriptType');
};
</script>


<style lang="scss" scoped>
.video-video {
    flex-wrap: wrap;
    max-width: 100%;
    box-sizing: border-box;
    min-height: 540px;
    max-height: 540px !important;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.video-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    /* 水平居中 */
    gap: 0px;
    /* 卡片之间的间距 */
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
}

/* Webkit 滚动条样式（适用于 Chrome、Safari 和 Edge） */
.custom-video::-webkit-scrollbar {
    width: 8px;
    /* 设置滚动条宽度 */
    height: 8px;
    /* 设置横向滚动条宽度 */
}

.custom-video::-webkit-scrollbar-track {
    background-color: #2b2e3d;
    /* 滚动条轨道背景 */
    border-radius: 10px;
    /* 轨道圆角 */
}

.custom-video::-webkit-scrollbar-thumb {
    background-color: #888;
    /* 滚动条的颜色 */
    border-radius: 10px;
    /* 滚动条的圆角 */
    border: 2px solid #2b2e3d;
    /* 滚动条的边框 */
}

.custom-video::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* 滚动条悬停时的颜色 */
}
</style>