import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "view-type-selection" }
const _hoisted_2 = {
  key: 0,
  class: "navbar"
}
const _hoisted_3 = { class: "top-navbar" }
const _hoisted_4 = { class: "content-area" }
const _hoisted_5 = {
  key: 1,
  class: "vue-form"
}

import { ref, computed } from 'vue';

import VideoType from './VideoType.vue';
import VideoForm from './VideoForm.vue';
import { useStore } from 'vuex';

// 创建一个状态变量，用来存储当前选中的视图

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoTypeSelection',
  setup(__props) {

const activeView = ref('ad');
const store = useStore();

const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);
const selectedTitle = computed(() => store.getters['ViewPlan/getSelectedTitle']);

// 返回上一步，显示导航栏和视频卡片
const goBack = () => {
  store.commit('ViewPlan/setIsCorporateViewVisible', false);
  store.commit('ViewPlan/setSelectedTitle', '');
  store.commit('ViewPlan/setVideoData', null);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isCorporateViewVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("nav", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'ad' }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (activeView.value = 'ad'))
            }, "广告", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'trailer' }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (activeView.value = 'trailer'))
            }, "宣传", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'cartoon' }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (activeView.value = 'cartoon'))
            }, "动画", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'short_story' }]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (activeView.value = 'short_story'))
            }, "短剧", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'short_video' }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (activeView.value = 'short_video'))
            }, "短视频", 2),
            _createElementVNode("span", {
              class: _normalizeClass(["nav-link", { active: activeView.value === 'ip_creation' }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (activeView.value = 'ip_creation'))
            }, "IP打造", 2)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(VideoType, { type: activeView.value }, null, 8, ["type"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(VideoForm, {
            selectedTitle: selectedTitle.value,
            onGoBack: goBack
          }, null, 8, ["selectedTitle"])
        ]))
  ]))
}
}

})