<template>
    <div class="storyboard-main">
        <!-- 大标题 -->
        <h1 class="table-title">《{{ storyboardName }}》</h1>
        <table class="custom-table">
            <thead>
                <tr>
                    <th>操作</th>
                    <th>序</th>
                    <th>效果图</th>
                    <th>画面内容</th>
                    <th>对应旁白</th>
                    <th>镜头</th>
                    <th>元素</th>
                    <th>秒</th>
                    <th>风格</th>
                    <th>图像提示词</th>
                    <th>视频提示词</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in tableData" :key="row.id" :class="{ selected: selectedRow === index }"
                    @click="selectRow(index)" @dragover.prevent @drop="onDrop(index)">
                    <td class="actions">
                        <div class="action-container">
                            <button class="add-button" @click="deleteRow(index)">-</button>
                            <!-- 只在拖拽按钮上设置 draggable -->
                            <button class="drag-button" draggable="true" @dragstart="onDragStart(index, $event)"
                                @dragover.prevent @drop="onDrop(index)">☰</button>
                            <button class="sub-button" @click="addRow(index)">+</button>
                        </div>
                    </td>
                    <td>{{ index + 1 }}</td> <!-- 镜头号，保持顺序 -->
                    <td>
                        <div class="image-container">
                            <!-- 当图片有值时 -->
                            <img v-if="row.image" :src="row.image" alt="效果图" class="image-preview"
                                @click="openImageModal(row.image)" crossorigin="anonymous" />

                            <!-- 当图片为空时 -->
                            <div v-else class="placeholder">
                                等待
                            </div>

                            <!-- 蒙版 -->
                            <div v-if="row.isGenerating" class="overlay">
                                <span class="loading-text">正在生成中...</span>
                            </div>
                        </div>

                        <!-- 上传图片的按钮和重新生成按钮 -->
                        <div class="button-container">
                            <!-- 上传图片按钮 -->
                            <div class="upload-img" @click="triggerFileInput(index)">
                                <i class="bi bi-box-arrow-up"></i>
                                <!-- 隐藏的文件输入框，使用 ref 绑定 -->
                                <input type="file" accept="image/*" style="display: none"
                                    @change="handleFileChange(index, $event)"
                                    :ref="(el) => { if (el) fileInputRefs[index] = el as HTMLInputElement; }" />

                            </div>

                            <!-- 重新生成按钮 -->
                            <div class="re-img">
                                <i class="bi bi-arrow-clockwise" @click="regenerateImage(row)"></i>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="content">
                            <textarea v-model="row.content" class="textarea-box" rows="1" />
                        </div>
                    </td>
                    <td>
                        <div class="content">
                            <textarea v-model="row.voiceover" class="textarea-box" rows="1" />
                        </div>
                    </td>
                    <td>
                        <div class="camera-lens">
                            <div>
                                <label>景别：</label>
                                <select v-model="row.shot_size" class="select-box">
                                    <option v-for="option in shotSizeOptions" :key="option" :value="option">{{ option }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label>角度：</label>
                                <select v-model="row.camera_angle" class="select-box">
                                    <option v-for="option in angleOptions" :key="option" :value="option">{{ option }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label>运镜：</label>
                                <select v-model="row.camera_motion" class="select-box">
                                    <option v-for="option in motionOptions" :key="option" :value="option">{{ option }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="elements">

                            <div class="character" @click.self="toggleDropdown(index)">
                                <!-- 已选中的角色框 -->
                                <div class="selected-characters-box" @click="toggleDropdown(index)">
                                    <label>角色：</label>
                                    <span
                                        v-if="selectedCharactersByRow[row.id] && selectedCharactersByRow[row.id].length">
                                        {{ selectedCharactersByRow[row.id].join(' ') }}
                                    </span>
                                    <span v-else>没有角色</span>
                                </div>

                                <!-- 复选框选择列表，点击外部隐藏 -->
                                <div v-if="currentDropdownIndex === index" class="custom-dropdown">
                                    <div class="checkbox-group">
                                        <label v-for="(character, idx) in availableCharacters" :key="character"
                                            class="checkbox-label">
                                            <input type="checkbox" :value="character"
                                                v-model="selectedCharactersByRow[row.id]"
                                                @change="updateCharacter(row)" />
                                            <span class="checkbox-custom"></span>
                                            {{ character }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <label>场景：</label>
                            <select v-model="row.scene" class="select-box">
                                <option v-for="option in sceneOptions" :key="option" :value="option">{{ option }}
                                </option>
                            </select>
                        </div>
                    </td>

                    <td>
                        <input type="text" v-model="row.duration" class="input-box" />
                    </td>
                    <td>
                        <div class="style">
                            <select v-model="row.style" class="select-box">
                                <option v-for="option in styleOptions" :key="option" :value="option">{{ option }}
                                </option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="prompt">
                            <textarea v-model="row.image_prompt" class="textarea-box" rows="1" />
                        </div>
                    </td>
                    <td>
                        <div class="prompt">
                            <textarea v-model="row.video_prompt" class="textarea-box" rows="1" />
                        </div>
                    </td>
                </tr>
            </tbody>

        </table>



        <div class="table-actions">
            <button class="btn continue" @click="generationNext">继续生成分镜</button>

            <button class="btn save" @click="saveStoryboard">保存</button>

            <button class="btn download" @click="downoadFrom">下载表格</button>
            <button class="btn generate" @click="navigateToVideoEditing">生成剪辑</button>
        </div>

        <!-- 模态框显示放大图片 -->
        <div v-if="isImageModalOpen" class="image-modal" @click="closeImageModal">
            <img :src="currentImage" alt="放大效果图" class="modal-image" crossorigin="anonymous" />
        </div>
    </div>
</template>


<script lang="ts" setup>
import { ref, watch, nextTick, onMounted, onBeforeUnmount, computed, Ref } from 'vue';
import { useRouter } from 'vue-router'; // 引入 useRouter
import { useStore } from 'vuex';
import type { TableRow } from '@/store/modules/ViewStoryboard';


const router = useRouter(); // 初始化 router 实例
const selectedRow = ref(0); // 默认选中第一行
const isImageModalOpen = ref(false); // 控制模态框的显示状态
const currentImage = ref(''); // 存储当前点击的图片 URL
const draggedRowIndex = ref<number | null>(null); // 当前被拖拽的行索引


// 初始化 store
const store = useStore();
// 通过 computed 获取 Vuex 中的 tableData
const tableData = computed(() => store.getters['ViewStoryboard/getTableData'] || []);
const storyboardName = computed(() => store.getters['ViewStoryboard/getStoryboardName'] || '');

// 用来存储文件输入框的动态引用
const fileInputRefs = ref<{ [key: number]: HTMLInputElement | null }>({});

// 触发文件输入框
const triggerFileInput = (index: number) => {
    // 通过 fileInputRefs 获取对应索引的文件输入框
    const fileInput = fileInputRefs.value[index];
    if (fileInput) {
        (fileInput as HTMLInputElement).click();  // 类型断言为 HTMLInputElement
    }
};

// 处理文件上传
const handleFileChange = async (index: number, event: Event) => {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
        // 使用 FileReader 读取文件为 Base64
        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result as string;

            console.log('Base64 编码的图片数据:', base64String);

            // 更新表格数据，使用 Base64 作为图片数据
            tableData.value[index].image = base64String;
        };

        reader.onerror = (error) => {
            console.error('文件读取失败:', error);
        };

        reader.readAsDataURL(file); // 读取文件为 Data URL (Base64)
    }
};

// 为了避免同时点击多次，使用 row 的 isGenerating 状态
const regenerateImage = async (row: any): Promise<void> => {
    const id = row.id;

    // 如果图片正在生成，直接返回
    if (row.isGenerating) {
        console.log('图片正在生成，请稍等...');
        return;
    }

    // 设置 isGenerating 为 true，表示正在生成图片
    store.commit('ViewStoryboard/setIsGenerating', { id, isGenerating: true });

    try {
        // 等待图片生成完成
        await store.dispatch('ViewStoryboard/generateImageForRowAction', id);
        console.log(`图片 ${id} 生成完成`);
    } catch (error) {
        console.error(`生成图片失败，行 ID: ${id}`, error);
    } finally {
        // 生成完成后，无论成功还是失败，都将 isGenerating 设置为 false
        store.commit('ViewStoryboard/setIsGenerating', { id, isGenerating: false });
    }
};




// 当前显示下拉框的行索引，默认不显示
const currentDropdownIndex = ref<number | null>(null);

// 可自定义的角色列表，不与 tableData 耦合
const availableCharacters = ref<string[]>(['嫦娥', '后羿', '玉兔', '吴刚', '天蓬']);

// 保存每一行的已选择角色，使用 string 作为键
const selectedCharactersByRow = ref<Record<string, string[]>>({});

// 点击选中框时，显示/隐藏选择列表
const toggleDropdown = (index: number) => {
    if (currentDropdownIndex.value === index) {
        currentDropdownIndex.value = null; // 如果点击的是当前的下拉框，则关闭
    } else {
        currentDropdownIndex.value = index; // 否则打开对应的下拉框
    }
};

// 更新每行角色选择后的回调，将角色数组转化为空格分隔的字符串
const updateCharacter = (row: any) => {
    let selectedCharacters = selectedCharactersByRow.value[row.id];

    // 确保 selectedCharacters 是一个数组，如果不是则初始化为空数组
    if (!Array.isArray(selectedCharacters)) {
        selectedCharacters = [];
        selectedCharactersByRow.value[row.id] = selectedCharacters; // 初始化为空数组
    }

    // 将选中的角色通过空格分隔连接成字符串并存储到 row.character
    row.character = selectedCharacters.join(' ');

    // 同步更新 Vuex 模块中的数据
    store.dispatch('ViewStoryboard/updateRowAction', { id: row.id, updatedRow: { character: row.character } });
};

// 点击页面其他地方时关闭选择框
const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.character')) {
        currentDropdownIndex.value = null; // 点击外部时，关闭所有下拉框
    }
};

// 定义每个 select 的选项列表
const styleOptions = ref(["插画风", "写实风", "商务风", "现代都市风"]);
const sceneOptions = ref(["梦幻场景", "现实场景"]);
const motionOptions = ref(["前推镜头", "后拉镜头", "摇镜头", "平移镜头", "跟拍镜头", "甩镜头", "上升镜头", "下降镜头", "环绕镜头", "穿越镜头"]);
const angleOptions = ref(["俯拍", "仰拍", "侧面（45°）", "正侧面", "背面"]);
const shotSizeOptions = ref(["大远景", "远景", "全景", "中景", "近景", "特写", "大特写"]);

// 通用方法：检查初始值是否在选项列表中，不在则添加
function checkAndAddOption(value: string, options: Ref<string[]>) {
    if (value && !options.value.includes(value)) {
        options.value.push(value);
    }
}


// 添加新行
const addRow = (index: number) => {
    console.log('Add row clicked at index:', index); // 调试日志，确认点击的行索引

    // 深拷贝行数据，确保所有字段都被复制
    const newRow = JSON.parse(JSON.stringify(tableData.value[index]));
    newRow.id = Date.now().toString(); // 为新行生成唯一 ID

    // 调用模块中的 addRow 方法，并传递插入位置
    store.dispatch('ViewStoryboard/addRowAction', { newRow, insertIndex: index + 1 });

    // 更新行号或序列
    updateShotNumbers();
};

// 删除行
const deleteRow = (index: number) => {
    if (tableData.value.length === 1) {
        console.log('无法删除最后一行');
        return; // 不允许删除最后一行
    }
    tableData.value.splice(index, 1); // 从表格中删除行
    updateShotNumbers(); // 重新更新所有行的index
    console.log(tableData.value)
};

// 更新数据
const updateRowData = (id: string, updatedData: Partial<typeof tableData.value[0]>) => {
    store.dispatch('ViewStoryboard/updateRowAction', { id, updatedRow: updatedData }); // 更新模块中的数据
};

// 更新镜头号
const updateShotNumbers = () => {
    tableData.value.forEach((row: TableRow, index: number) => {
        const updatedIndex = index + 1; // 重新编号，从 1 开始
        if (row.index !== updatedIndex) {
            row.index = updatedIndex; // 更新本地数据
            updateRowData(row.id, { index: updatedIndex }); // 同步更新到模块中的数据
        }
    });
};

// 打开图片模态框
const openImageModal = (imageUrl: string) => {
    currentImage.value = imageUrl;
    isImageModalOpen.value = true;
};

// 关闭图片模态框
const closeImageModal = () => {
    isImageModalOpen.value = false;
};

// 自动调整 textarea 高度
const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'; // 重置高度，避免高度累积
    textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容设置高度
};

// 自动调整所有 textarea 高度
const autoResizeAll = () => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => autoResize(textarea as HTMLTextAreaElement));
};

// // 自动调整 input 宽度
// const adjustInputWidth = (input: HTMLInputElement) => {
//     input.style.width = `${input.value.length || 1}ch`; // 根据字符数动态调整宽度
// };

// 为 textarea 和 input 添加事件监听
const addListeners = () => {
    document.querySelectorAll('textarea').forEach((textarea) => {
        textarea.addEventListener('input', (event) => autoResize(event.target as HTMLTextAreaElement));
    });

    // document.querySelectorAll('input[type="text"]').forEach((input) => {
    //     input.addEventListener('input', (event) => adjustInputWidth(event.target as HTMLInputElement));
    // });
};

// 选择行
const selectRow = (index: number) => {
    selectedRow.value = index;
};

// 拖拽开始，只在按钮上触发拖拽逻辑，并手动设置拖拽数据
const onDragStart = (index: number, event: DragEvent) => {
    draggedRowIndex.value = index;

    // 将 event.target 断言为 HTMLElement，确保可以调用 closest
    const draggedRowElement = (event.target as HTMLElement)?.closest('tr') as HTMLElement;
    if (draggedRowElement) {
        event.dataTransfer?.setData('text/html', draggedRowElement.outerHTML);
        event.dataTransfer?.setDragImage(draggedRowElement, 0, 0); // 让拖拽时显示的图像为整行
    }
};

// 放置拖拽行
const onDrop = (index: number) => {
    if (draggedRowIndex.value !== null && draggedRowIndex.value !== index) {
        const draggedRow = tableData.value.splice(draggedRowIndex.value, 1)[0];
        tableData.value.splice(index, 0, draggedRow);
        updateRowData; // 更新数据
        selectRow(index); // 选中新行
        updateShotNumbers();
        console.log(tableData.value)
    }
    draggedRowIndex.value = null;
};


// 在组件挂载时自动执行调整
onMounted(async () => {

    await store.dispatch('ViewStoryboard/getCurrentStoryboard');

    tableData.value.forEach((row: TableRow) => {
        checkAndAddOption(row.style, styleOptions);
        checkAndAddOption(row.scene, sceneOptions);
        checkAndAddOption(row.camera_motion, motionOptions);
        checkAndAddOption(row.camera_angle, angleOptions);
        checkAndAddOption(row.shot_size, shotSizeOptions);
    });

    document.addEventListener('click', handleClickOutside);
    nextTick(() => {
        autoResizeAll();  // 页面加载或返回时，调整所有 textarea 高度
        addListeners();   // 添加事件监听，确保新的 textarea 和 input 可以自动调整
    });
});


// 监听 tableData 的变化，确保新添加的行的默认值也能加入选项列表中
watch(tableData, (newTableData) => {
    newTableData.forEach((row: TableRow) => {
        checkAndAddOption(row.style, styleOptions);
        checkAndAddOption(row.scene, sceneOptions);
        checkAndAddOption(row.camera_motion, motionOptions);
        checkAndAddOption(row.camera_angle, angleOptions);
        checkAndAddOption(row.shot_size, shotSizeOptions);
    });
}, { deep: true });


onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});

// 监听 tableData 的变化，并初始化 selectedCharactersByRow
watch(
    tableData,
    (newVal) => {
        newVal.forEach((row: TableRow) => {
            if (typeof row.character === 'string' && row.character.length > 0) {
                selectedCharactersByRow.value[row.id] = row.character.split(' ');
            }
        });
    },
    { immediate: true, deep: true }
);

// 监听 tableData 中 content 的变化
watch(
    () => tableData.value?.map((row: TableRow) => row.content) ?? [], // 若 tableData.value 为 undefined 则返回空数组
    () => {
        nextTick(() => {
            autoResizeAll(); // 调整所有 textarea 的高度
            addListeners();  // 添加事件监听
        });
    },
    { deep: true }
);


// 下载表格
const downoadFrom = () => {
    alert("下载表格功能未开发");
};

const generationNext = async () => {
    await store.dispatch('ViewStoryboard/generationNext')
}

const saveStoryboard = async () => {
    await store.dispatch('ViewStoryboard/saveStoryboard')
}

// 跳转到 video-editing 路由的方法
const navigateToVideoEditing = async () => {
    router.push({ path: '/video-editing' }); // 页面跳转
    await store.dispatch('ViewStoryboard/generateVoiceoverAction')
    await store.dispatch('ViewEdit/initVideoSegments');
};
</script>



<style scoped lang="scss">
.storyboard-main {
    background-color: rgba(29, 33, 42, 1);
    padding: 0px;
    margin-top: 10px;
    border-radius: 10px;
    max-width: 100%;
}

.table-title {
    text-align: center;
    /* 标题居中 */
    color: white;
    /* 白色字体 */
    font-weight: bold;
    /* 加粗字体 */
    margin-top: 0;
    /* 与表格之间留出间距 */
    margin-bottom: 0;
    font-size: 20px;
}

.custom-table-wrapper {
    width: 100%;
    overflow-x: auto !important;
    /* 允许水平滚动 */
    max-width: 100% !important;
    /* 确保容器不会超出视口宽度 */
    border: 1px solid #3a3a4d;
    /* 可选：加个边框让其更明显 */
}

.custom-table {
    overflow: hidden;
    /* 超出部分隐藏 */
    border-collapse: collapse;
    background-color: rgba(51, 55, 66, 1);
    width: 100%;
    font-size: 15px;
}

.custom-table th,
.custom-table td {
    color: white;
    border: 4px solid #1f1f20;
    padding: 5px;
    text-align: center;

    /* 强制设置每列的最大宽度 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 内容过长时显示省略号 */

}

.custom-table th {
    background-color: rgba(44, 58, 97, 1);
}

.custom-table td {
    background-color: rgba(51, 55, 66, 1);
}

.custom-table tbody tr.selected td {
    outline: 2px solid #9ba0a5;
    /* 设置选中行的外边框 */

}

.image-preview {
    padding: 0;
    border: 0;
    cursor: pointer;
    width: 150px;
    border-radius: 5px;
    transition: transform 0.3s;
}

.image-preview:hover {
    transform: scale(1.1);
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
}

.modal-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
}

/* 包裹图片和蒙版的容器 */
.image-container {
    position: relative;
    display: inline-block;
}

/* 蒙版样式 */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    /* 半透明黑色背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    z-index: 1;
    /* 保证蒙版在图片上层 */
}

/* 文字样式 */
.loading-text {
    color: white;
    /* 白色字体 */
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    z-index: 11;
    /* 确保文字层级高于蒙版 */
}

.placeholder {
    background-color: rgb(126, 122, 122);
    /* 半透明黑色背景 */
    min-width: 150px;
    min-height: 84.3px;
    border-radius: 8px;
    color: white;
    /* 白色字体 */
    font-size: 15px;
    /* 让内容上下左右居中 */
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
}



.action-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-button {
    background-color: #ca4848;
    border: none;
    padding: 5px;
    margin: 2px 0;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-weight: bold;
    transition: background-color 0.3s;
    /* 平滑过渡效果 */
}

.add-button:hover {
    background-color: #ff5c5c;
    /* 悬浮时变亮 */
}

.drag-button {
    background-color: #565656;
    border: none;
    padding: 5px;
    margin: 2px 0;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-weight: bold;
    transition: background-color 0.3s;
    /* 平滑过渡效果 */
}

.drag-button:hover {
    background-color: #707070;
    /* 悬浮时颜色变亮 */
}

.sub-button {
    background-color: #3368c5;
    border: none;
    padding: 5px;
    margin: 2px 0;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-weight: bold;
    transition: background-color 0.3s;
    /* 平滑过渡效果 */
}

.sub-button:hover {
    background-color: #4b85e8;
    /* 悬浮时颜色变亮 */
}


.input-box {
    text-align: center;
    min-width: 20px;
    /* 设置最小宽度 */
    width: 25px;
    /* 宽度自适应 */
    padding: 5px;
    font-size: 12px;
    border: 1px solid transparent;
    /* 透明边框 */
    outline: none;
    /* 去除默认的输入框高亮 */
    border-radius: 4px;
    transition: border-color 0.3s;
    /* 自动扩展宽度 */
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    /* 保证宽度自适应 */
}

/* 在输入框聚焦时显示浅色边框 */
.input-box:focus {
    border-color: #007bff;
}




.select-box {
    min-width: 50px;
    /* 设置最小宽度 */
    text-align: center;
    width: auto;
    /* 宽度自适应 */
    padding: 5px;
    font-size: 12px;
    /* 透明边框 */
    border: 1px solid transparent;
    border-radius: 4px;
    /* 统一背景色 */
    appearance: none;
    /* 隐藏默认的下拉箭头 */
    outline: none;
    display: inline-block;
    /* 保证与 input-box 一致 */
    color: #333;
    cursor: pointer;
}

/* 下拉箭头的自定义 */
.select-box::after {
    content: '▾';
    /* 使用自定义符号替代默认箭头 */
    position: absolute;
    right: 10px;
    pointer-events: none;
}

/* 针对下拉菜单项（仅限部分浏览器生效，如 Chrome） */
.select-box option {
    background-color: #c0bcbc;
    /* 设置下拉菜单展开后的背景颜色 */
    color: rgb(75, 73, 73);
    /* 设置下拉选项的字体颜色 */
}

/* 当下拉框聚焦时，背景颜色不变 */
.select-box:focus {
    background-color: #c0bcbc;
    /* 聚焦时背景颜色不变 */
    border-color: lightgray;
    /* 可选的聚焦时边框颜色 */
}

/* 父容器确保箭头不受文本影响 */
.select-box-container {
    position: relative;
    display: inline-block;
}

.textarea-box {
    min-width: 100%;
    /* 占满父容器宽度 */
    max-width: 100%;
    /* 防止超出父容器 */
    padding: 5px;
    font-size: 12px;
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #333;
    outline: none;
    resize: none;
    /* 禁用用户手动调整大小 */
    overflow: hidden;
    /* 禁用滚动条 */
    box-sizing: border-box;
    transition: border-color 0.3s, box-shadow 0.3s;
}

/* 禁用状态样式 */
.textarea-box:disabled {
    background-color: #f8f9fa;
    color: #6c757d;
    cursor: not-allowed;
}

.character {
    text-align: left;
}

/* 已选中角色的容器样式 */
.character .selected-characters-box {
    padding: 5px;
    padding-left: 0;
    /* 内边距设置为 5px */
    cursor: pointer;
    /* 鼠标悬停时显示为手型 */
    border-radius: 4px;
    /* 边框圆角 */
    width: 120px;
    /* 宽度设置为 200px */
    display: inline-block;
    /* 显示为行内块元素 */
}

/* 已选中角色文本的样式 */
.character .selected-characters-box span {
    font-size: 12px !important;
    /* 强制设置字体大小为 15px */
    color: white !important;
    /* 强制设置字体颜色为白色 */
    font-weight: normal !important;
    /* 确保字体不加粗 */
}

/* 自定义下拉菜单的样式 */
.custom-dropdown {
    position: absolute;
    /* 绝对定位，使下拉菜单可以根据父元素定位 */
    background-color: white;
    /* 背景颜色为白色 */
    border: 1px solid #ccc;
    /* 边框颜色为浅灰色 */
    border-radius: 4px;
    /* 边框圆角 */
    padding: 10px;
    /* 内边距设置为 10px */
    width: 200px;
    /* 宽度设置为 200px */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* 设置轻微的阴影效果 */
    z-index: 100;
    /* 确保下拉菜单在其他元素上方显示 */
}

/* 复选框组的容器样式，包含所有复选框 */
.checkbox-group {
    max-height: 150px;
    /* 最大高度为 150px */
    overflow-y: auto;
    /* 超出内容时出现垂直滚动条 */
}

/* 单个复选框标签的样式 */
.checkbox-label {
    display: flex;
    /* 使用 flex 布局，方便对齐复选框和文本 */
    align-items: center;
    /* 垂直居中对齐复选框和文本 */
    padding: 5px;
    /* 内边距设置为 5px */
    position: relative;
    /* 相对定位，为绝对定位的复选框自定义样式做准备 */
    cursor: pointer;
    /* 鼠标悬停时显示为手型 */
    font-size: 12px;
    /* 设置复选框标签的字体大小为 18px */
    color: #333;
    /* 字体颜色为深灰色 */
}

/* 隐藏原生复选框 */
.checkbox-label input {
    position: absolute;
    /* 绝对定位隐藏复选框 */
    opacity: 0;
    /* 设置透明度为 0，隐藏原生复选框 */
    cursor: pointer;
    /* 鼠标悬停时显示为手型 */
}

/* 自定义复选框样式 */
.checkbox-custom {
    position: relative;
    /* 相对定位，用于自定义选中样式 */
    height: 16px;
    /* 设置复选框的高度为 16px */
    width: 16px;
    /* 设置复选框的宽度为 16px */
    background-color: #eee;
    /* 复选框的背景颜色为浅灰色 */
    border-radius: 3px;
    /* 边框圆角为 3px */
    display: inline-block;
    /* 显示为行内块元素 */
    border: 1px solid #aaa;
    /* 边框颜色为中灰色 */
    margin-right: 8px;
    /* 复选框与文本之间的间距设置为 8px */
}

/* 复选框被选中时的样式 */
.checkbox-label input:checked+.checkbox-custom {
    background-color: #2196f3;
    /* 选中状态时，复选框的背景颜色变为蓝色 */
    border: none;
    /* 去掉边框 */
}

/* 复选框被选中时的打勾样式 */
.checkbox-label input:checked+.checkbox-custom::after {
    content: '';
    /* 设置空内容，作为打勾符号 */
    position: absolute;
    /* 绝对定位，使打勾符号放置在复选框内部 */
    left: 5px;
    /* 左边距设置为 5px，使打勾居中 */
    top: 1px;
    /* 顶部边距设置为 1px */
    width: 5px;
    /* 打勾的宽度 */
    height: 10px;
    /* 打勾的高度 */
    border: solid white;
    /* 打勾符号的颜色为白色 */
    border-width: 0 2px 2px 0;
    /* 设置打勾的边框宽度 */
    transform: rotate(45deg);
    /* 旋转 45 度，形成打勾形状 */
}

.custom-width {
    width: 120px;
}

/* 通用样式 */
.textarea-box,
.select-box,
.input-box {
    background-color: transparent;
    /* 背景色透明 */
    color: white;
    /* 字体颜色变为白色 */
    padding: 5px;
    border-radius: 4px;
    max-width: 100%;
    /* 让输入框不超过单元格宽度 */
}

.textarea-box:focus,
.select-box:focus,
.input-box:focus {
    outline: none;
    /* 移除默认的聚焦样式 */
    border-color: lightgray;
    /* 聚焦时的边框颜色 */
}

.camera-lens {
    width: 140px;
    text-align: left;
}

.camera-lens option {
    text-align: left;
}

.elements {
    text-align: left;
}

.elements option {
    text-align: left;
}

/* 用 Flexbox 实现按钮的水平排列，确保按钮靠左 */
.button-container {
    display: flex;
    gap: 10px;
    /* 按钮之间的间距 */
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    /* 靠左对齐 */
}

/* 公共按钮样式 */
.upload-img i,
.re-img i {
    font-size: 12px;
    background-color: #007bff;
    /* 蓝色主题 */
    color: white;
    border: none;
    border-radius: 50%;
    padding: 4px;
    /* 统一的内边距 */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    /* 添加变换效果 */
}

.upload-img i:hover,
.re-img i:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.upload-img,
.re-img {
    margin-top: 5px;
    /* 统一设置按钮的上间距 */
}


.style {
    width: 86px;
}


.content {
    width: 120px;
}

.prompt {
    width: 190px;
}

label {
    font-size: 12px;
}


.btn {
    background-color: #007bff;
    /* 蓝色主题 */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #0056b3;
    /* 深蓝色悬停效果 */
    transform: scale(1.05);
    /* 微微放大 */
}


.table-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between; /* 左右对齐 */
    padding: 10px;
    gap: 20px;
}

.table-actions .btn {
    width: auto;
}

.continue {
    margin-right: auto; /* 将 continue 按钮推到左边 */
}

</style>
