<template>
  <div class="video-editing">
    <div class="editor-container">
      <!-- 视频播放器容器 -->
      <div class="video-player-container">
        <!-- 视频播放器 -->
        <video ref="videoPlayer" class="video-player" controls :src="combinedVideoUrl" @play="startDrawingFrames"
          @pause="stopDrawingFrames" crossorigin="anonymous"></video>
        <div class="video-controls">
          <span>{{ formattedTime }}</span>
          <button @click="togglePlayPause" class="play-pause-button">
            {{ isPlaying ? '暂停' : '播放' }}
          </button>
        </div>
      </div>

      <div class="edit-page">
        <!-- 时间刻度条 -->
        <div id="time-scale">
          <div id="timeline-track" class="timeline-track">
            <div v-for="mark in timeMarks" :key="mark.time" class="time-mark" :style="{ left: mark.position + '%' }">
              {{ mark.label }}
            </div>
          </div>
          <div :style="{ left: pointerPosition + '%' }" class="playback-pointer" @mousedown="startDragging"></div>
        </div>

        <div id="timeline-container">
          <div v-for="(segment, index) in videoSegments" :key="index"
            :class="{ 'selected-segment': store.state.ViewEdit.selectedVideo === segment.video_id }"
            :style="{ width: segment.width + '%', backgroundColor: segment.backgroundColor, }" class="video-segment"
            @click="selectSegment(segment.video_id)">
            <!-- 视频名称 -->
            <!-- <div class="segment-title">{{ segment.name }}</div> -->
            <!-- 视频缩略图 -->
            <img :src="segment.thumbnail" class="segment-thumbnail" crossorigin="anonymous" />
          </div>

          <div :style="{ left: pointerPosition + '%' }" class="playback-pointer" @mousedown="startDragging"></div>
        </div>

        <!-- 音轨部分 -->
        <div class="audio-track">
          <!-- 音轨 -->
          <div class="audio-track-bar"></div>
          <div :style="{ left: pointerPosition + '%' }" class="playback-pointer" @mousedown="startDragging"></div>
        </div>

        <!-- 旁白部分 -->
        <div class="subtitle-track">
          <!-- 旁白 -->
          <div class="subtitle-track-bar"></div>
          <div :style="{ left: pointerPosition + '%' }" class="playback-pointer" @mousedown="startDragging"></div>
        </div>

      </div>
      <input type="range" id="zoom-slider" min="10" max="120" value="60">

      <div class="control-buttons">
        <button @click="addSegment">增加镜头</button>
        <button @click="removeSegment">减少镜头</button>
        <button @click="downloadAllAssets">一键下载所有素材</button>
      </div>
    </div>

    <!-- 条件渲染 CameraDetails 组件 -->
    <div class="details" v-show="showDetails">
      <CameraDetails />
    </div>


  </div>
</template>

<script lang="ts" setup>
import CameraDetails from './CameraDetails.vue';
import { ref, computed, onMounted, onActivated, onDeactivated, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';

// 获取 Vuex store 实例 
const store = useStore();

// 使用 `ViewEdit` 模块的状态
const videoSegments = computed(() => store.state.ViewEdit.videoSegments);

// 获取 Vuex 中的 combinedVideoUrl
const combinedVideoUrl = computed(() => store.state.ViewEdit.combinedVideoUrl);

const player = ref<HTMLVideoElement | null>(null);

const timelineContainer = ref<HTMLElement | null>(null);

// 获取 Vuex 状态中的 isPlaying
const isPlaying = computed(() => store.state.ViewEdit.isPlaying);

// 从 Vuex 获取 timeMarks
const timeMarks = computed(() => store.state.ViewEdit.timeMarks);


// 从 Vuex 中获取 `showDetails` 的状态
const showDetails = computed({
  get: () => store.state.ViewEdit.isShowDetails,
  set: (value) => store.commit('ViewEdit/setIsShowDetails', value)
});

// 选择视频段的函数
const selectSegment = (videoId: string) => {
  // 获取当前 `selectedVideo` 的值进行判断
  const currentSelectedVideo = store.state.ViewEdit.selectedVideo;
  console.log('currentSelectedVideo:', currentSelectedVideo);

  if (currentSelectedVideo === videoId) {
    // 如果点击的是相同视频段，切换 `showDetails` 状态
    showDetails.value = !showDetails.value;
    console.log("改变showDetails的值")
  } else {
    // 如果点击的是不同视频段，更新 `selectedVideo` 并显示 `CameraDetails`
    store.commit('ViewEdit/setSelectedVideo', videoId);
    showDetails.value = false;
  }
};

// 清除选择的函数
const clearSelection = () => {
  store.commit('ViewEdit/clearSelection');
};

// 全局点击处理
const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  // 检查点击的目标是否在 `timeline-container` 或 `details` 内部
  if (
    !target.closest('#timeline-container') &&
    !target.closest('.details')
  ) {
    clearSelection(); // 点击外部区域时清除选择
  }
};

// 播放指针位置（百分比）
const playbackPosition = computed(() => {
  const playerElement = player.value;
  const currentTime = store.state.ViewEdit.currentTime;
  const duration = playerElement?.duration || 0;

  // 计算并返回百分比位置
  return duration ? (currentTime / duration) * 100 : 0;
});


// 拖动相关状态
const isDragging = ref(false);
const currentTime = ref(0); // 用于保存当前播放时间
const draggedPosition = ref(0); // 拖动时的播放指针位置（百分比）

// 指针的显示位置：拖动时显示 `draggedPosition`，否则显示 `playbackPosition`
const pointerPosition = computed(() => (isDragging.value ? draggedPosition.value : playbackPosition.value));

// 开始拖动
const startDragging = (event: MouseEvent) => {
  if (!player.value || player.value.readyState < 1) return;

  // 记录拖动前的播放状态并暂停
  store.commit('ViewEdit/setPlayingState', !player.value.paused);
  player.value.pause();

  isDragging.value = true;
  document.addEventListener('mousemove', onDragging);
  document.addEventListener('mouseup', stopDragging);
};

// 拖动时更新指针位置
const onDragging = (event: MouseEvent) => {
  if (!isDragging.value || !timelineContainer.value || !player.value) return;

  // 计算拖动位置的百分比
  const rect = timelineContainer.value.getBoundingClientRect();
  const offsetX = event.clientX - rect.left;
  const newPosition = Math.max(0, Math.min(offsetX / rect.width, 1));
  draggedPosition.value = newPosition * 100; // 更新拖动指针的百分比位置

  // 仅更新 `currentTime`，不实时更新 `player.currentTime`
  currentTime.value = newPosition * player.value.duration;
};

// 停止拖动
const stopDragging = () => {
  isDragging.value = false;
  document.removeEventListener('mousemove', onDragging);
  document.removeEventListener('mouseup', stopDragging);

  // 松开鼠标后更新播放器时间
  if (player.value) {
    player.value.currentTime = currentTime.value;
    store.commit('ViewEdit/setCurrentTime', currentTime.value);
  }

  // 恢复播放状态
  if (store.state.ViewEdit.isPlaying && player.value) {
    player.value.play();
  }
};

// 播放或暂停
const togglePlayPause = () => {
  if (!player.value) return;

  if (player.value.paused) {
    player.value.play().then(() => {
      setTimeout(() => {
        store.commit('ViewEdit/setPlayingState', true); // 确保播放开始后再更新状态
      }, 50);
    }).catch(error => {
      console.error("尝试播放时出错:", error);
    });
  } else {
    player.value.pause();
    setTimeout(() => {
      store.commit('ViewEdit/setPlayingState', false); // 确保暂停后再更新状态
    }, 50);
  }
};

// 启动视频帧绘制到 Canvas
const startDrawingFrames = () => {
  store.dispatch('ViewEdit/startDrawingFrames');
};

// 停止绘制帧
const stopDrawingFrames = () => {
  store.dispatch('ViewEdit/stopDrawingFrames');
}

// 格式化时间为 mm:ss
const formattedTime = computed(() => {
  const currentTime = store.state.ViewEdit.currentTime; // 获取当前播放时间
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
})


// 生成时间刻度
const generateTimeMarks = () => {
  if (player.value && player.value.duration) {
    const duration = Math.floor(player.value.duration);

    const newTimeMarks = Array.from({ length: duration + 1 }, (_, index) => {
      const time = index;
      const position = (time / duration) * 100;
      const minutes = Math.floor(time / 60);
      const seconds = (time % 60).toString().padStart(2, '0');
      return { time, position, label: `${minutes}:${seconds}` };
    });

    store.commit('ViewEdit/setTimeMarks', newTimeMarks);
    console.log("Generated time marks:", newTimeMarks);
  }
};


onMounted(async () => {
  // 设置视频播放器和时间轴容器
  player.value = document.querySelector('.video-player') as HTMLVideoElement;
  timelineContainer.value = document.getElementById('timeline-container') as HTMLElement;

  // 合成视频的 URL 设置到播放器中
  if (player.value && combinedVideoUrl.value) {
    player.value.src = combinedVideoUrl.value;
    console.log("Combined video URL set to player:", combinedVideoUrl.value);
  }

  // 播放器的时间更新事件
  const timeUpdateHandler = () => {
    const currentTime = player.value?.currentTime || 0;
    store.commit('ViewEdit/setCurrentTime', currentTime);

    if (player.value?.ended) {
      store.commit('ViewEdit/setPlayingState', false);
    }
  };

  // 视频元数据加载事件
  const metadataLoadedHandler = generateTimeMarks;

  // 监听播放器时间更新事件
  player.value?.addEventListener('timeupdate', timeUpdateHandler);

  // 视频元数据加载完成后生成时间刻度
  player.value?.addEventListener('loadedmetadata', metadataLoadedHandler);

  // 监听键盘事件，支持空格键切换播放/暂停
  const keydownHandler = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      event.preventDefault();
      togglePlayPause();
    }
  };

  // 动态监听 `isShowDetails` 的变化
  watch(
    () => store.state.ViewEdit.isShowDetails,
    (newVal) => {
      if (!newVal) {
        // 当 isShowDetails 为 false 时添加监听器
        document.addEventListener('keydown', keydownHandler);
      } else {
        // 当 isShowDetails 为 true 时移除监听器
        document.removeEventListener('keydown', keydownHandler);
      }
    },
    { immediate: true } // 确保初始状态也执行
  );

  // 监听点击事件，用于处理点击外部关闭等操作
  document.addEventListener('click', handleClickOutside);

  // 组件销毁时移除所有监听器
  onUnmounted(() => {
    player.value?.removeEventListener('timeupdate', timeUpdateHandler);
    player.value?.removeEventListener('loadedmetadata', metadataLoadedHandler);
    document.removeEventListener('keydown', keydownHandler);
    document.removeEventListener('click', handleClickOutside);
  });
});

// 添加和减少镜头功能
const addSegment = () => console.log('增加镜头');
const removeSegment = () => console.log('减少镜头');

const downloadAllAssets = async () => {
  const assetUrls = await store.dispatch('ViewEdit/getAllAssetUrls');
  
  // 下载每个视频 URL
  for (const [index, url] of assetUrls.videoUrls.entries()) {
    await downloadFile(url, `video-${index + 1}.mp4`);
  }

  // 下载音乐、合成音频和合成视频文件
  if (assetUrls.musicUrl) await downloadFile(assetUrls.musicUrl, 'music.mp3');
  if (assetUrls.generatedAudioUrl) await downloadFile(assetUrls.generatedAudioUrl, 'generated-audio.mp3');
  if (assetUrls.combinedVideoUrl) await downloadFile(assetUrls.combinedVideoUrl, 'combined-video.mp4');

  // 获取 tableData 并下载每个 row.image
  const tableData = store.getters['ViewStoryboard/getTableData'] || [];
  for (const [index, row] of tableData.entries()) {
    if (row.image) {
      await downloadFile(row.image, `image-${index + 1}.jpg`);
    }
  }
};

// 使用 fetch 和 Blob 实现文件下载，避免打开链接
const downloadFile = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    // 打印下载内容的信息
    console.log(`Downloading file: ${fileName}`);
    // console.log(`URL: ${url}`);
    // console.log(`File type: ${blob.type}`);
    // console.log(`File size: ${blob.size} bytes`);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // 释放 URL 对象
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error(`Failed to download file from ${url}`, error);
  }
};

onActivated(() => console.log('VideoEditingView 被激活'));
onDeactivated(() => console.log('VideoEditingView 被缓存'));

</script>


<style scoped lang="scss">
.video-editing {
  display: flex;
  /* 启用 Flex 布局 */
  justify-content: center;
  /* 水平居中 */
  font-size: 15px;
  margin: 0;
  padding: 0;
  position: relative;
  /* 设置为相对定位，以便子元素可以绝对定位 */
}

/* 编辑器主容器样式 */
.editor-container {
  width: 90%;
  max-width: 1200px;
  background-color: #101115;
  border-radius: 12px;
  margin: 0;
  padding: 0;
}

/* 视频播放器的容器样式 */
.video-player-container {
  position: relative;
  width: 50%;
  margin: 20px auto;
  margin-top: 0;
}

/* 视频播放器样式 */
.video-player {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: block;
}

/* 视频播放器控件样式 */
.video-controls {
  position: relative;
  margin: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  opacity: 1;
  transition: opacity 0.3s;
}

.play-pause-button {
  background-color: #007bff;
  /* 蓝色主题 */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.play-pause-button:hover {
  background-color: #0056b3;
  /* 深蓝色悬停效果 */
  transform: scale(1.05);
  /* 微微放大 */
}

.video-player-container:hover .video-controls {
  opacity: 1;
}

/* 时间刻度指针样式 */
.time-scale-pointer {
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #ff6b6b;
  cursor: pointer;
}

/* 时间刻度条样式 */
#time-scale {
  position: relative;
  width: 100%;
  background-color: #2c2f35;
  /* 颜色稍微变暗，减少视觉疲劳 */
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  color: #ccc;
}

/* 时间线轨道样式 */
.timeline-track {
  position: relative;
  width: 100%;
  height: 15px;
  /* 加大高度提升用户体验 */
  background-color: #3b3f47;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.4);
  /* 内阴影增加层次感 */
}

/* 时间刻度样式 */
.time-mark {
  position: absolute;
  bottom: 0;
  height: 15px;
  font-size: 12px;
  color: #e0e0e0;
  text-align: center;
  transform: translateX(-50%);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  /* 半透明边框，减少突兀感 */
  padding-left: 2px;
  white-space: nowrap;
  /* 防止时间标签换行 */
  pointer-events: none;
  /* 防止用户误点击 */
  user-select: none;
  /* 防止文本被选中 */
}

/* 时间线容器样式 */
#timeline-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 70px;
  background-color: #3b3f47;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(63, 32, 32, 0.4);
}

/* 音轨样式 */
.audio-track {
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #3b3f47;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

/* 旁白样式 */
.subtitle-track {
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #3b3f47;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
}

/* 视频片段样式 */
.video-segment {
  position: relative;
  height: 87%;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: grab;
}

.selected-segment {
  border: 2px solid blue !important;
  opacity: 0.5 !important;
}


.segment-title {
  display: block;
  font-size: 12px;
  color: white;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
}


/* 视频片段缩略图样式 */
.segment-thumbnail {
  position: relative;
  max-width: 80%;
  /* 确保宽度不超过容器的 100% */
  max-height: 80%;
  width: auto !important;
  border-radius: 4px;
  cursor: grab;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
   /* 禁用选中效果 */
   user-select: none;
}

/* 播放指针样式 */
.playback-pointer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background-color: #ff6b6b;
  cursor: pointer;
}

/* 缩放滑块样式 */
#zoom-slider {
  width: 40%;
  margin: 10px auto;
  display: block;
  /* 确保滑块居中 */
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #61dafb;
  border-radius: 5px;
  outline: none;
}

/* 控制按钮样式 */
.control-buttons {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.control-buttons button {
  background-color: #007bff;
  /* 蓝色主题 */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.control-buttons button:hover {
  background-color: #0056b3;
  /* 深蓝色悬停效果 */
  transform: scale(1.05);
  /* 微微放大 */
}

.audio-track-bar {
  position: absolute;
  top: 20%;
  left: 1%;
  height: 60%;
  width: 98%;
  background-color: #bbcc20;
  /* 蓝色背景 */
  z-index: 0;
  /* 确保在播放指针后面 */
}


.subtitle-track-bar {
  position: absolute;
  top: 20%;
  left: 1%;
  height: 60%;
  width: 98%;
  background-color: #c24de3;
  /* 蓝色背景 */
  z-index: 0;
  /* 确保在播放指针后面 */
}

.details {
  position: absolute;
  bottom: 0px;
  /* 让 details 底部对齐到 editor-container */
  left: 50%;
  transform: translateX(-50%);
  /* 水平居中 */
  width: auto;
  /* 使其宽度根据内容自动调整 */
  background-color: #ffffff;
  /* 背景色，方便观察布局 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 给 details 添加阴影 */
  padding: 0px;
  border-radius: 8px;
}
</style>
