// src/composables/useForm.ts
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export function useForm(moduleName: string) {
  const store = useStore();

  const form = computed(() => store.getters[`${moduleName}/formData`]);
  const errors = computed(() => store.getters[`${moduleName}/errors`]);
  const formLabels = computed(() => store.getters[`${moduleName}/formLabels`]);
  const requiredFields = computed(() => store.getters[`${moduleName}/requiredFields`]);

  const isRequired = (key: string) => requiredFields.value.includes(key);

  const validateForm = () => {
    let isValid = true;
    requiredFields.value.forEach((field: string) => {
      if (!form.value[field]) {
        store.commit(`${moduleName}/setError`, { field, hasError: true });
        isValid = false;
      } else {
        store.commit(`${moduleName}/setError`, { field, hasError: false });
      }
    });
    return isValid;
  };

  const uploadedFile = ref<File | null>(null);
  const handleFileUpload = (type: string) => (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      uploadedFile.value = target.files[0];
      console.log(`${type === 'doc' ? 'Related document' : 'Video'} uploaded:`, uploadedFile.value);
    }
  };

  const handleSubmit = async () => {
    const isValid = validateForm();
    if (!isValid) {
      alert('请填写所有必填项！');
      return;
    }

    try {
      const response = await store.dispatch(`${moduleName}/submitForm`);
      if (response && response.success) {
        alert('提交成功');
      } else {
        alert(`生成失败: ${response.message}`);
      }
    } catch (error) {
      alert(`发生错误: ${error}`);
    }
  };

  return {
    form,
    errors,
    formLabels,
    isRequired,
    handleSubmit,
    handleFileUpload
  };
}
