<template>
    <div class="topBar">
        <img src="@/assets/logo2.png" class="logo-img" crossorigin="anonymous">
        <div class="Bar">
            <div class="topBarTitle">金木妙影AI视频创作</div>
            <div class="BarTitle">垂类定制 / 一键生成 / 高效创作 / 版权无忧</div>
        </div>
        <button class="logoutButton" @click="handleLogout">登出</button>

        
    </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const handleLogout = () => {
    store.dispatch('auth/logout'); // 调用 Vuex 的登出 action
    router.push('/login'); // 登出后重定向到登录页面
};
</script>

<style scoped lang="scss">
.topBar {
    position: relative;
    /* 为了使内部绝对定位元素生效 */
    display: flex;
    justify-content: center;
    /* 水平居中标题 */
    align-items: center;
    /* 垂直居中 */
    height: 60px;
    font-size: 30px;
    background-color: #1F2229;
    padding: 0 20px;
}

.logo-img {
    margin-right: 20px;
    height: 50px;
}

.Bar {
    display: flex; /* 启用 flexbox 布局 */
    justify-content: center; /* 水平居中 */
    flex-direction: column; /* 垂直排列子元素 */
    align-items: center; /* 垂直居中的同时也水平居中 */
}

.topBarTitle {
    font-size: 25px;
    color: #FFFFFF;
    font-weight: bold;
}

.BarTitle {
    color: #FFFFFF;
    font-size: 12px;
}

.logoutButton {
    position: absolute;
    /* 绝对定位按钮 */
    right: 20px;
    /* 距离右侧边距 */
    background-color: #007bff;
    color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.logoutButton:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}
</style>