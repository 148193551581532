import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-box" }
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = { class: "input-group" }

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLogin',
  setup(__props) {

const store = useStore();
const router = useRouter();

const username = ref<string>('');
const password = ref<string>('');

const handleSubmit = async () => {
  try {
    const response = await axios.post('https://api.aizdc.com/api/login', {
      username: username.value,
      password: password.value
    });

    if (response.data.success) {
      const { token, username, userID } = response.data;

      // 存储到 localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      localStorage.setItem('userID', userID);

      // 使用 Vuex 存储用户信息
      store.dispatch('auth/login', { token, username, userID });

      // 跳转到 plan 页面
      router.push({ path: '/plan' });
    } else {
      console.error('登录失败:', response.data.message);
      alert('用户名或密码错误');
    }
  } catch (error) {
    console.error('请求错误:', error);
    alert('登录请求失败，请稍后再试。');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "avatar" }, null, -1)),
      _createElementVNode("form", {
        onSubmit: _withModifiers(handleSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "username" }, "用户名", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            id: "username",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event)),
            required: ""
          }, null, 512), [
            [_vModelText, username.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "password" }, "密码", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            id: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
            required: ""
          }, null, 512), [
            [_vModelText, password.value]
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("button", { type: "submit" }, "登录", -1))
      ], 32)
    ])
  ]))
}
}

})