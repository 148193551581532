<template>
  <div class="planMain">
    <h2 class="page-title">创意生成</h2>

    <!-- 显示当前页的修改意见，使用只读的 textarea -->
    <!-- <div class="input-display">
      <div class="input-display-div">{{ currentPageInput  }}</div>
    </div> -->

    <div class="content">
      <!-- 内容展示框，使用 textarea 替代 div -->
      <textarea class="content-box" v-model="currentPageContent" rows="10"
        placeholder="1、请在左侧选择您要制作的脚本类型。 2、填写一些脚本相关的必要信息。  3、填写完毕后，点击策划生成按钮。 4、稍等片刻。 5、在下方的修改意见输入您的意见。 6、修改到满意的版本后，点击文案生成按钮，进行下一步的文案生成。"></textarea>
    </div>

    <!-- 分页导航 -->
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 0">
        <i class="bi bi-arrow-left-circle-fill"></i>
      </button>
      <span> &nbsp; {{ currentPage + 1 }} / {{ totalPages }} &nbsp; </span>
      <button @click="nextPage" :disabled="currentPage === totalPages - 1">
        <i class="bi bi-arrow-right-circle-fill"></i>
      </button>
    </div>


    <!-- 输入新的修改意见 -->
    <div class="feedback">
      <textarea v-model="newFeedback" class="feedback-textarea" @input="autoResize"
        @keydown.enter.prevent="sendFeedback" placeholder="输入您的修改意见"></textarea>
      <button class="sendButton" @click="sendFeedback">发送</button>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router'; // 引入 Vue Router
import { useStore } from 'vuex';

const router = useRouter(); // 获取 router 实例
// 使用 Vuex store
const store = useStore();
const currentPage = computed(() => store.getters['ViewPlan/getCurrentPage']);
const totalPages = computed(() => store.getters['ViewPlan/getTotalPages']);
const currentPageContent = computed({
  get: () => store.getters['ViewPlan/getCurrentPageContent'],
  set: (newContent) => store.commit('ViewPlan/updatePageContent', { index: currentPage.value, content: newContent })
});
const currentPageInput = computed(() => store.getters['ViewPlan/getCurrentPageInput']);
const newFeedback = ref(''); // 初始化为当前页的输入内容

// 自动调整textarea高度
const autoResize = (event: Event) => {
  const textarea = event.target as HTMLTextAreaElement;
  // 重置高度，防止高度累积
  textarea.style.height = 'auto';
  // 动态设置textarea高度，使其适应内容
  textarea.style.height = `${textarea.scrollHeight}px`;
};


// 翻页功能
const prevPage = () => {
  if (currentPage.value > 0) {
    store.commit('ViewPlan/setCurrentPage', currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value - 1) {
    store.commit('ViewPlan/setCurrentPage', currentPage.value + 1);
  }
};

// 保存修改意见
const sendFeedback = () => {
  if (!newFeedback.value.trim()) {
    alert('请输入有效的修改意见');
    return;
  }
  store.dispatch('ViewPlan/insertModRes', { index: currentPage.value, input: newFeedback.value });
  // 清空 textarea 内容
  newFeedback.value = '';
};

</script>

<style scoped lang="scss">
.planMain {
  color: white;
  padding: 20px;
  width: 660px;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 0;
  padding-top: 10px;
  margin-bottom: 10px;
}

.page-title {
  margin-top: 0;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  margin-bottom: 10px;
}

.input-display {
  float: right;
  margin-right: 10px;
  margin-left: 80px;
  background-color: #2b2e3d;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}




.content {
  padding-top: 20px;
  background-color: #2b2e3d;
  min-height: 50vh;
  overflow: auto;
  border-radius: 8px;
}


.content-box {
  background-color: #2b2e3d;
  padding: 15px;
  padding-left: 40px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 90%;
  resize: none;
  /* 禁止 textarea 的大小调整 */
  color: white;
  border: none;
  outline: none;
  font-size: 14px;
  padding-top: 0;
  min-height: 52vh;
  overflow: auto;
  /* 启用滚动条 */

  /* 设置文本区域内滚动条样式 */
  scrollbar-width: thin;
  /* Firefox - 设置滚动条宽度 */
  scrollbar-color: #888 #2b2e3d;
  /* Firefox - 设置滚动条颜色 */
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top: 10px;
}

.pagination button {
  background-color: transparent;
  /* 取消背景色 */
  border: none;
  cursor: pointer;
  padding: 0;
  /* 去掉内边距，确保图标不被填充 */
}

.pagination button i {
  font-size: 24px;
  /* 设置图标大小 */
  color: #1a73e8;
  transition: color 0.3s ease;
  /* 为图标颜色添加过渡效果 */
}

.pagination button:hover i {
  color: #135ba1;
  ;
  /* 悬浮时改变图标颜色 */
}

.pagination button:disabled i {
  color: gray;
  /* 禁用状态下图标变灰 */
  cursor: not-allowed;
}


.feedback {
  display: flex;
  /* 使用 Flexbox 布局 */
  align-items: flex-end;
  /* 垂直对齐到底部 */
  margin-left: 10px;
  margin-right: 0px;
  margin-top: 0;
  margin-bottom: 0;
}

.feedback-textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  resize: none;
  margin-top: 5px;
  height: 40px;
  background-color: #2b2e3d;
  color: white;
  outline: none;
  max-height: 100px;
  /* 最大高度为100px */
  overflow-y: auto;
  /* 内容超出后显示滚动条 */
  word-wrap: normal;
  /* 正常换行 */
  white-space: pre-wrap;
  /* 保持空格和换行 */
  line-height: 1.2;
  /* 控制行距 */
  font-size: 14px;
  max-height: 74px;
  box-sizing: border-box;
}

.sendButton {
  padding: 8px 16px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 70px;
  margin-left: 10px;
}

.sendButton:hover {
  background-color: #135ba1;
}

.send-button button {
  background-color: #1a73e8;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button button:hover {
  background-color: #135ba1;
}


/* Webkit 滚动条样式（适用于 Chrome、Safari 和 Edge） */
textarea::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条宽度 */
  height: 8px;
  /* 设置横向滚动条宽度 */
}

textarea::-webkit-scrollbar-track {
  background-color: #2b2e3d;
  /* 滚动条轨道背景 */
  border-radius: 10px;
  /* 轨道圆角 */
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 滚动条的颜色 */
  border-radius: 10px;
  /* 滚动条的圆角 */
  border: 2px solid #2b2e3d;
  /* 滚动条的边框 */
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 滚动条悬停时的颜色 */
}
</style>
