<template>
  <div class="plan">
    <div class="video-type">
      <VideoTypeSelectionView />
    </div>
    <div class="plan-main">
      <PlanwritingMainView />
    </div>
    <div class="video-setting">
      <PlanSetting />
    </div> 

  </div>
</template>

<script lang="ts" setup>
import { ref, onActivated, onDeactivated } from 'vue';
import VideoTypeSelectionView from '@/views/Plan/VideoTypeSelection.vue';
import PlanwritingMainView from '@/views/Plan/PlanMainView.vue';
import PlanSetting from '@/views/Plan/PlanSetting.vue';


// 生命周期钩子
onActivated(() => {
  console.log('planView 被激活');
});

onDeactivated(() => {
  console.log('planView 被缓存');
});


</script>


<style scoped lang="scss">
.plan {
  display: flex;
  /* 使用 flex 布局 */
  min-height: 100%;
  /* 页面全屏高度 */
  background-color: #101115;
}

.video-type {
  flex: 1;
  margin: 5px;
  /* 设置左右间距 */
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  align-items: center;
  /* 垂直居中对齐 */
  background-color: #1a1b20;
  /* 示例背景色 */
  border-radius: 8px;
}

.plan-main {
  flex: 2;
  margin: 5px;
  /* 设置左右间距 */
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  background-color: #1a1b20;
  /* 示例背景色 */
  border-radius: 8px;
}

.video-setting {
  flex: 1;
  margin: 5px;
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  background-color: #1a1b20;
  /* 示例背景色 */
  border-radius: 8px;
}
</style>