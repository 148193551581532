<template>
  <div class="view-type-selection">
    <div class="navbar" v-if="!isCorporateViewVisible">
      <!-- 顶部导航栏 -->
      <nav class="top-navbar">
        <span class="nav-link" :class="{ active: activeView === 'ad' }" @click="activeView = 'ad'">广告</span>
        <span class="nav-link" :class="{ active: activeView === 'trailer' }" @click="activeView = 'trailer'">宣传</span>
        <span class="nav-link" :class="{ active: activeView === 'cartoon' }" @click="activeView = 'cartoon'">动画</span>
        <span class="nav-link" :class="{ active: activeView === 'short_story' }"
          @click="activeView = 'short_story'">短剧</span>
        <span class="nav-link" :class="{ active: activeView === 'short_video' }"
          @click="activeView = 'short_video'">短视频</span>
        <span class="nav-link" :class="{ active: activeView === 'ip_creation' }"
          @click="activeView = 'ip_creation'">IP打造</span>
      </nav>

      <!-- 内容区域 -->
      <div class="content-area">
        <!-- 动态组件渲染 -->
        <VideoType :type="activeView" />
      </div>
    </div>

    <!-- 显示表单 -->
    <div v-else class="vue-form">
      <VideoForm :selectedTitle="selectedTitle" :onGoBack="goBack" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';

import VideoType from './VideoType.vue';
import VideoForm from './VideoForm.vue';
import { useStore } from 'vuex';

// 创建一个状态变量，用来存储当前选中的视图
const activeView = ref('ad');
const store = useStore();

const isCorporateViewVisible = computed(() => store.getters['ViewPlan/getIsCorporateViewVisible']);
const selectedTitle = computed(() => store.getters['ViewPlan/getSelectedTitle']);

// 返回上一步，显示导航栏和视频卡片
const goBack = () => {
  store.commit('ViewPlan/setIsCorporateViewVisible', false);
  store.commit('ViewPlan/setSelectedTitle', '');
  store.commit('ViewPlan/setVideoData', null);
};
</script>

<style lang="scss" scoped>
.view-type-selection {
  width: 100%;
  min-height: 97%;
  height: auto;
  padding: 5px;
  border-radius: 8px;
  // background-color: #1F2229;
}

.top-navbar {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.nav-link {
  color: #b9e4dc;
  margin: 0 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #0CC5B3;
}

.nav-link.active {
  color: #0CC5B3;
  /* 选中时的颜色 */
}

.content-area {
  flex: 1;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  justify-items: center;
}

.vue-form {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
}
</style>
