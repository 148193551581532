import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StoryboardView from '../views/Storyboard/StoryboardView.vue'
import VideoEditingView from '../views/VideoEditing/VideoEditingView.vue'
import PlanView from '@/views/Plan/PlanView.vue'
import NotFoundView from '../views/NotFoundView.vue' // 导入404页面
import UserLogin from "@/views/UserLogin.vue";
import MaterialView from '@/views/AIMaterial/MaterialView.vue'
import HotspotView from '@/views/AIHotspot/HotspotView.vue'
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  { path: '/login', component: UserLogin },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/plan',
    meta: { requiresAuth: true }, // 添加 requiresAuth
    children: [
      {
        path: 'hotspot',
        name: 'hotspot',
        component: HotspotView
      },
      {
        path: 'plan',
        name: 'plan',
        component: PlanView
      },
      {
        path: 'storyboard',
        name: 'storyboard',
        component: StoryboardView
      },
      {
        path: 'video-editing',
        name: 'video-editing',
        component: VideoEditingView
      },
      {
        path: 'ai-material',
        name: 'ai-material',
        component: MaterialView
      },

    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:pathMatch(.*)*', // 捕获所有未匹配的路由
    name: 'NotFound',
    component: NotFoundView // 404页面组件
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = store.getters['auth/token']; // 从 auth 模块获取 token
    if (token) {
      next(); // 用户已登录，允许进入
    } else {
      next('/login'); // 用户未登录，重定向到登录页面
    }
  } else {
    next(); // 不需要认证的路由，直接放行
  }
});

export default router
