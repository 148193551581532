import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "storyboard" }
const _hoisted_2 = { class: "tool" }
const _hoisted_3 = { class: "storyboard" }

import { ref, onActivated, onDeactivated } from 'vue';
import StoryboardTool from './StoryboardTool.vue';
import StoryboardMain from './StoryboardMain.vue';

// 生命周期钩子

export default /*@__PURE__*/_defineComponent({
  __name: 'StoryboardView',
  setup(__props) {

onActivated(() => {
  console.log('StoryboardView 被激活');
});

onDeactivated(() => {
  console.log('StoryboardView 被缓存');
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(StoryboardTool)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(StoryboardMain)
    ])
  ]))
}
}

})