import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "material" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { class: "search" }

import MaterialMainView from './MaterialMainView.vue';
import MaterialSearchView from './MaterialSearchView.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MaterialMainView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(MaterialSearchView)
    ])
  ]))
}
}

})