<template>
      <div class="main-material" >
        <!-- Navigation-style selection with explicit buttons -->
        <nav class="material-nav">
            <button :class="{ active: selectedOption === 'voiceover' }" @click="setSelectedOption('voiceover', $event)">
                <i class="bi bi-mic"></i> 配音
            </button>
            <button :class="{ active: selectedOption === 'music' }" @click="setSelectedOption('music', $event)">
                <i class="bi bi-music-note"></i> 配乐
            </button>
            <button :class="{ active: selectedOption === 'image' }" @click="setSelectedOption('image', $event)">
                <i class="bi bi-image"></i> 图片
            </button>
            <button :class="{ active: selectedOption === 'video' }" @click="setSelectedOption('video', $event)">
                <i class="bi bi-camera-video"></i> 视频
            </button>
            <button :class="{ active: selectedOption === 'design' }" @click="setSelectedOption('design', $event)">
                <i class="bi bi-paint-bucket"></i> 设计
            </button>
        </nav>

        <!-- Display content based on selection -->
        <div class="content-display">
            <!-- Dynamically load components based on the selected option -->
            <component :is="selectedOptionComponent"></component>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import VoiceoverComponent from './VoiceoverComponent.vue';
import MusicComponent from './MusicComponent.vue';
import ImageComponent from './ImageComponent.vue';
import VideoComponent from './VideoComponent.vue';
import DesignComponent from './DesignComponent.vue';
import { useStore } from 'vuex';

const store = useStore()

const selectedOption = computed({
  get: () => store.getters['ViewMaterial/getSelectedOption'],
  set: (value: string | null) => {
    store.dispatch('ViewMaterial/updateSelectedOption', value);
  },
});

// Mapping of selected options to their corresponding components
const selectedOptionComponent = computed(() => {
    switch (selectedOption.value) {
        case 'voiceover':
            return VoiceoverComponent;
        case 'music':
            return MusicComponent;
        case 'image':
            return ImageComponent;
        case 'video':
            return VideoComponent;
        case 'design':
            return DesignComponent;
        default:
            return null;
    }
});


// 处理按钮点击并切换选择状态的函数
function setSelectedOption(option: string, event: MouseEvent) {
    // 阻止事件传播到父级元素
    event.stopPropagation();

    // 如果再次点击相同的选项，取消选择（设置为 null）
    if (selectedOption.value === option) {
        selectedOption.value = null;
    } else {
        // 否则，设置为当前选中的选项
        selectedOption.value = option;
    }
}


</script>

<style scoped lang="scss">
.main-material {
    width: 100%;
    padding-bottom: 10px;
    background: linear-gradient(90deg,
            rgba(20, 20, 20, 1) 0%,
            rgba(51, 50, 82, 1) 4.32%,
            rgba(62, 92, 87, 1) 10.46%,
            rgba(22, 158, 196, 1) 23.39%,
            rgba(57, 179, 164, 1) 49.95%,
            rgba(22, 146, 171, 1) 78.71%,
            rgba(66, 86, 125, 1) 88.23%,
            rgba(64, 91, 92, 1) 94.18%,
            rgba(27, 33, 31, 1) 100%);
            
}

h1 {
    color: white;
}

.material-nav {
    display: flex;
    justify-content: center;
    /* Center the buttons */
    gap: 20px;
    margin:0;
}

.material-nav button {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    /* Stack the icon and text vertically */
    align-items: center;
    /* Center the content horizontally */
    padding: 10px 15px;
    font-size: 1rem;
    color: white;
    background: none;
    border: 2px solid white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 0;
}

.material-nav button.active {
    background-color: #159ac4;
    /* Highlight active selection */
    border-color: #159ac4;
    color: white;
}

.material-nav button:hover {
    background-color: #159ac4;
    color: white;
}

.material-nav i {
    font-size: 1.5rem;
    /* Set a larger size for the icons */
    margin-bottom: 5px;
    /* Space between icon and text */
}

// .content-display{
   
// }


.content-display p {
    color: white;
    font-size: 1.2rem;
}
</style>
