import instance from './index'; // 从 api/index.ts 引入封装的 axios 实例


// 获取会话的 API
export const getSession = (bot_id: string, userID: string) => {
    return instance.post('/api/getSession', { bot_id, userID }); // 提交 bot_id 和 userID 获取 sessionId
};

// 调用 chatWithBot 的 API
export const chatWithBot = (data: {
    user_token: string;
    conversation_id: string;
    bot_id: string;
    userInput: string;
    fileUrl?: string; // 文件 URL 可选
}) => {
    const formData = new FormData();
    formData.append("user_token", data.user_token);
    formData.append("conversation_id", data.conversation_id);
    formData.append("bot_id", data.bot_id);
    formData.append("userInput", data.userInput);

    // 如果有文件 URL，则添加到 FormData
    if (data.fileUrl) {
        formData.append("file_url", data.fileUrl);
    }

    // 使用封装的 axios 实例提交数据
    return instance.post('/chatWithBot', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'  // 指定 Content-Type 以便处理文件
        }
    });
};

