// store/modules/Cvoiceover.ts
import { Module } from 'vuex';
import { getVoiceoverByUser, voiceoverSynthesis, getSoundProfiles, getAllVoiceoverByUser } from '@/api/voiceover'; // 引入接口方法

// Define the state structure for the material module
export interface State {
    selectedVoice: string;
    soundProfiles: Array<{ sound_id: string; sound_name: string; description: string; voice_sample: string; icon: string }>;
    scriptText: string; // 用于保存文案内容
    maxLength: number;  // 最大字数限制
    voiceoverRecords: Record<string, Array<any>>; // 不同 sound_id 的配音记录
    userVoiceoverRecords: Record<string, Array<any>>; // 用户的所有配音记录
}

// Initial state
const state: State = {
    selectedVoice: '', // Default selected voice
    soundProfiles: [], // Array to store all sound profiles
    scriptText: '这是又一个配音测试的文案',     // 文案内容
    maxLength: 500,     // 最大字数限制
    voiceoverRecords: {}, // 不同 sound_id 的配音记录
    userVoiceoverRecords: {} // 用户的所有配音记录
};

// Mutations
const mutations = {
    setSelectedVoice(state: State, option: string) {
        state.selectedVoice = option;
    },
    setSoundProfiles(state: State, profiles: Array<{ sound_id: string; sound_name: string; description: string; voice_sample: string; icon: string }>) {
        state.soundProfiles = profiles;
    },
    setScriptText(state: State, text: string) {
        state.scriptText = text;
    },
    setMaxLength(state: State, length: number) {
        state.maxLength = length;
    },
    setVoiceoverRecords(state: State, payload: { sound_id: string; records: Array<any> }) {
        state.voiceoverRecords[payload.sound_id] = payload.records;
    },
    addVoiceoverRecord(state: State, payload: { sound_id: string; record: any }) {
        if (!state.voiceoverRecords[payload.sound_id]) {
            state.voiceoverRecords[payload.sound_id] = [];
        }
        state.voiceoverRecords[payload.sound_id].unshift(payload.record); // 使用 unshift 添加到开头
    },
    removeVoiceoverRecord(state: State, payload: { sound_id: string; record: any }) {
        state.voiceoverRecords[payload.sound_id] = state.voiceoverRecords[payload.sound_id].filter(record => record.id !== payload.sound_id);
    },
    setUserVoiceoverRecords(state: State, payload: { user_id: string; records: Array<any> }) {
        state.userVoiceoverRecords[payload.user_id] = payload.records;
    },

};

// Actions
const actions = {
    // Update the selected voice
    updateSelectedVoice({ commit }: { commit: any; }, option: string) {
        commit('setSelectedVoice', option);
        console.log('Selected voice updated:', option);
    },

    // 获取音色数据
    async fetchSoundProfiles({ commit }: { commit: any }) {
        try {
            const response = await getSoundProfiles();
            if (response.data.success) {
                commit('setSoundProfiles', response.data.data);
                console.log('Sound profiles fetched:', response.data.data);
            } else {
                console.error('Failed to fetch sound profiles');
            }
        } catch (error) {
            console.error('Error fetching sound profiles:', error);
        }
    },



    // 当更新时，获得新的表单
    async fetchVoiceoverIfNeeded(
        { commit, state, dispatch }: { commit: any, state: any, dispatch: any },
        payload: { userId: string, soundId: string }
    ) {
        // 获取当前的 selectedVoice 索引
        const selectedVoiceIndex = Number(state.selectedVoice) - 1;

        // 从 soundProfiles 中获取对应的 sound_id
        const sound_id = state.soundProfiles[selectedVoiceIndex]?.sound_id;

        if (!sound_id) {
            console.error('这个sound_id错误${sound_id}');
            return;
        }

        // 判断当前 voiceoverRecords 中是否已经存在指定的 sound_id
        const existingRecord = state.voiceoverRecords[sound_id];

        if (!existingRecord) {
            // 如果记录不存在，则调用 fetchVoiceoverByUser 来获取配音记录
            console.log(`获得当前配音记录${sound_id}`);
            await dispatch('fetchVoiceoverByUser', { userId: payload.userId, soundId: sound_id });
        } else {
            console.log(`当前配音记录 ${sound_id} 已经缓存，跳过重新加载.`);
        }
    },

    // 获取用户配音记录，按 sound_id 存储
    async fetchVoiceoverByUser({ commit }: { commit: any }, payload: { userId: string, soundId: string }) {
        try {
            const response = await getVoiceoverByUser(payload.userId, payload.soundId);
            if (response.data.success) {
                commit('setVoiceoverRecords', { sound_id: payload.soundId, records: response.data.data });
                console.log(`Voiceover records fetched for sound_id ${payload.soundId}:`, response.data.data);
            } else {
                console.log(`No voiceover records found for sound_id ${payload.soundId}`);
            }
        } catch (error) {
            console.error('Error fetching voiceover records:', error);
        }
    },

    // 获取用户配音记录，按 sound_id 存储
    async fetchAllVoiceoverByUser({ commit }: { commit: any }, payload: { userId: string }) {
        try {
            console.log("payload.userId:", payload.userId);
            
            const response = await getAllVoiceoverByUser(payload.userId);
            if (response.data.success) {
                commit('setUserVoiceoverRecords', { user_id: payload.userId, records: response.data.data });
                console.log(state.userVoiceoverRecords);
                
            } else {
                console.log(`No voiceover records found for sound_id ${payload.userId}`);
            }
        } catch (error) {
            console.error('Error fetching voiceover records:', error);
        }
    },

    // 创建新的配音，更新记录
    async createVoiceover({ state, commit, rootGetters, dispatch }: { state: State; commit: any; rootGetters: any; dispatch: any }) {
        try {
            const userId = rootGetters['auth/userID'];

            const username = rootGetters['auth/username'];
            const selectedVoiceIndex = Number(state.selectedVoice) - 1;

            // 检查 soundProfiles 和 selectedVoice 是否有效
            if (isNaN(selectedVoiceIndex) || selectedVoiceIndex < 0 || selectedVoiceIndex >= state.soundProfiles.length) {
                throw new Error("未找到对应的音色，请确认已选择音色");
            }

            const soundId = state.soundProfiles[selectedVoiceIndex]["sound_id"];
            const data = {
                text: state.scriptText,
                sound_id: soundId,
                user_id: userId,
                username: username
            };

            console.log("准备生成配音数据:", data);

            // 在 voiceoverRecords 中添加一条等待状态的数据
            const pendingVoiceover = {
                audio_url: '',
                id: '',
                sound_name: 'sound_name',
                script_text: state.scriptText,
                sound_id: soundId,
                formatted_created_at: 'now_time',
                icon: 'https://i.imgur.com/llF5iyg.gif',

            };

            // 如果 voiceoverRecords 中没有对应的 sound_id，则初始化数组
            if (!state.voiceoverRecords[soundId]) {
                commit('setVoiceoverRecords', { sound_id: soundId, records: [] });
            }

            // 添加等待数据到 voiceoverRecords
            commit('addVoiceoverRecord', { sound_id: soundId, record: pendingVoiceover });

            // 调用配音生成接口
            const response = await voiceoverSynthesis(data);

            if (response.data.success) {
                console.log('Voiceover created successfully');

                // 生成成功后调用 fetchVoiceoverByUser 更新配音记录
                await dispatch('fetchVoiceoverByUser', { userId, soundId });

                await dispatch('setUserVoiceoverRecords', { userId });
            } else {
                console.error('Failed to create voiceover');

                // 如果生成失败，从 voiceoverRecords 中移除等待状态的数据
                commit('removeVoiceoverRecord', { sound_id: soundId, record: pendingVoiceover });
            }
        } catch (error) {
            console.error('Error creating voiceover:', error);

            // 如果出现异常，从 voiceoverRecords 中移除等待状态的数据
            const soundId = state.soundProfiles[Number(state.selectedVoice) - 1]?.sound_id;
            if (soundId) {
                commit('removeVoiceoverRecord', { sound_id: soundId, record: { id: `pending-${Date.now()}` } });
            }
        }
    }

};

// Getters
const getters = {
    getSelectedVoice(state: State): string {
        return state.selectedVoice;
    },
    getSoundProfiles(state: State): Array<{ sound_id: string; sound_name: string; description: string; voice_sample: string; icon: string }> {
        return state.soundProfiles;
    },
    getScriptText: (state: State) => state.scriptText,
    getMaxLength: (state: State) => state.maxLength,

    getConvertedSoundId: (state: State) => {
        const selectedVoiceIndex = Number(state.selectedVoice) - 1;
        return state.soundProfiles[selectedVoiceIndex]?.sound_id || '';
    },

    getVoiceoverRecords: (state: State) => (soundId: string) => state.voiceoverRecords[soundId] || [],

    getUserVoiceoverRecords: (state: State) => (user_id: string) => state.userVoiceoverRecords[user_id] || [],


};

// Export the module
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
