<template>
  <div class="storyboard" >
    <div class="tool">
      <StoryboardTool />
    </div>
    <div class="storyboard">
      <StoryboardMain />
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, onActivated, onDeactivated } from 'vue';
import StoryboardTool from './StoryboardTool.vue';
import StoryboardMain from './StoryboardMain.vue';

// 生命周期钩子
onActivated(() => {
  console.log('StoryboardView 被激活');
});

onDeactivated(() => {
  console.log('StoryboardView 被缓存');
});

</script>

<style scoped lang="scss">
.storyboard {
  display: flex;
}

.tool {
  flex: 0.9; /* 占1份宽度 */
  border-right: 1px solid #3a3a4d; /* 边框分隔线 */
  border-radius: 10px;
}

.main {
  flex: 9.1; /* 占9份宽度 */
  max-width: 100%;
  background-color: #1e1e2f; /* 示例背景色 */
}


</style>
